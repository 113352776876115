import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Container,
  Grid,
  LinearProgress,
  TextField,
  Typography
} from "@mui/material";
import React from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../FirebaseProvider/FirebaseProvider";
import { Construction } from "@mui/icons-material";

export default function Login({ authError, authLoading }) {
  const [signInWithEmailAndPassword, user, loading, error] =
    useSignInWithEmailAndPassword(firebaseAuth);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    signInWithEmailAndPassword(data.get("email"), data.get("password"));
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
    >
      <Card
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 4
        }}
        elevation={8}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography
          component="h1"
          variant="h5"
        >
          Login Operatore
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            disabled={authLoading || loading}
            margin="normal"
            required
            fullWidth
            id="email"
            label="Username"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            disabled={authLoading || loading}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {(authLoading || loading) && (
            <Grid
              container
              sx={{ marginTop: 2 }}
            >
              <Grid
                xs
                item
              >
                <LinearProgress color="secondary" />
              </Grid>
            </Grid>
          )}
          {error && <Alert severity="error">{error.message}</Alert>}
          {user && <Alert severity="success">Accesso in corso...</Alert>}
          <Button
            disabled={authLoading || loading}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Login
          </Button>
        </Box>
      </Card>
      <Box
        mt={6}
        textAlign={"center"}
      >
        <Chip
          size="small"
          component={"a"}
          clickable
          href="https://www.lombardoandrea.com"
          target="_blank"
          icon={<Construction />}
          label="Andrea Lombardo"
        />
      </Box>
    </Container>
  );
}
