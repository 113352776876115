import React from "react";
import {runTransaction} from "firebase/firestore";
import {dbFirestore, refTicketsTokenData} from "../../FirebaseProvider/FirebaseProvider";
import {useConfirm} from "material-ui-confirm";
import {useSnackbar} from "notistack";
import {FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";
import {Reply} from "@mui/icons-material";

export default function TokenRefunder() {

    const confirm = useConfirm();
    const {enqueueSnackbar} = useSnackbar();

    const [tokenToRefund, setTokenToRefund] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);

    const handleOnInputChange = (e) => {
        const value = Number(e.target.value);
        if (value >= 0) {
            setTokenToRefund(value);
        }
    };

    const updateTokenOnFirebase = () => {

        try {
            return runTransaction(dbFirestore, async (transaction) => {

                const tokenDataDoc = await transaction.get(refTicketsTokenData);

                if (!tokenDataDoc.exists()) {
                    //errore documento di sintesi non trovato
                    return Promise.reject({
                        message: `Errore interno ${refTicketsTokenData.path} non trovato.`
                    });
                }

                const tokenDataData = tokenDataDoc.data();

                const newTicketsRefundOnSite = (tokenDataData.TICKETS_REFUNDED_ONSITE || 0) + tokenToRefund;

                transaction.update(refTicketsTokenData, {
                    TICKETS_REFUNDED_ONSITE: newTicketsRefundOnSite
                });

            });
        } catch (err) {
            enqueueSnackbar(err.message, {variant: "error"});
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        confirm({
            title: "Siamo sicuri?",
            description:
                `Stai davvero rimborsando ${tokenToRefund} token?`,
            confirmationText: "Si",
            cancellationText: "Annulla"
        })
            .then(() => {
                setLoading(true);
                return updateTokenOnFirebase();
            })
            .then(() => {
                enqueueSnackbar(`Valori aggiornati!`, {variant: "success"});
                setTokenToRefund(0);
            })
            .catch((e) => {
                setError(e);
                if (e) {
                    console.error(e);
                    enqueueSnackbar("Errore imprevisto!", {variant: "error"});
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormControl variant="outlined" fullWidth style={{marginTop: "1.5rem"}}>
                <InputLabel
                    htmlFor="outlined-adornment-token-to-refund">{loading ? "Operazione in corso..." : "Rimborsa Token"}</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-token-to-refund"
                    type="number"
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                disabled={loading}
                                color={"error"}
                                type={"submit"}
                                edge="end"
                            > <Reply/>
                            </IconButton>
                        </InputAdornment>
                    }
                    error={error}
                    label={loading ? "Operazione in corso..." : "Rimborsa Token"}
                    disabled={loading}
                    value={tokenToRefund || 0}
                    onChange={handleOnInputChange}
                />
            </FormControl>
        </form>
    );
}