import React from "react";
import {orderBy, query, onSnapshot} from "firebase/firestore";
import {Box, Chip, LinearProgress} from "@mui/material";
import {Check, HourglassEmpty, QrCode} from "@mui/icons-material";
import DataGridNoResult from "../DataGridNoResult/DataGridNoResult";
import CustomDataGrid from "../CustomDataGrid/CustomDataGrid";
import {GridActionsCellItem, GridToolbar} from "@mui/x-data-grid";
import {useSnackbar} from "notistack";
import PropTypes from "prop-types";

const formatDate = (seconds) => {
    return new Intl.DateTimeFormat("it", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
    }).format(new Date(seconds * 1000));
};

const RenderCheckedCell = ({value}) => {
    const label = <Box>{value ? formatDate(value) : "Non ancora smarcato"}</Box>;

    return (
        <Chip
            icon={value ? <Check/> : <HourglassEmpty/>}
            label={label}
            color={value ? "success" : "warning"}
            size="small"
        />
    );
};

const MemoizedRenderCheckedCell = React.memo(
    RenderCheckedCell,
    (prev, next) => {
        return prev.value === next.value;
    }
);

DataGridTicketsOnline.propTypes = {
    dgridProps: PropTypes.object,
    dgridWrapperBoxProps: PropTypes.object,
    onShowDetails: PropTypes.func,
    propRefColOnlineTickets: PropTypes.object.isRequired,
    forToken: PropTypes.bool.isRequired,
};

export default function DataGridTicketsOnline({
                                                  forToken,
                                                  dgridProps,
                                                  dgridWrapperBoxProps,
                                                  onShowDetails,
                                                  propRefColOnlineTickets
                                              }) {
    const {enqueueSnackbar} = useSnackbar();

    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const handleOnShowDetails = React.useCallback(
        (ticketId) => {
            if (!ticketId) {
                enqueueSnackbar("ID Ticket non pervenuto", {variant: "warning"});
                return;
            }
            const details = rows.find((row) => {
                return row.id === ticketId;
            });

            if (!details) {
                enqueueSnackbar("Dettagli non disponibili!", {variant: "warning"});
                return;
            }

            onShowDetails(
                <div>
                    <img
                        src={details.qrCode}
                        alt={details.qrCode}
                        title={details.qrCode}
                        style={{width: "50%", margin: "0 auto", display: "block"}}
                    />
                    <pre
                        style={{
                            width: "100%",
                            overflow: "auto",
                            height: "400px",
                            backgroundColor: "#ddd",
                            padding: "8px",
                            border: "1px solid #ccc"
                        }}
                    >
            {JSON.stringify(details, null, 4)}
          </pre>
                </div>
            );
        },
        [rows, onShowDetails, enqueueSnackbar]
    );

    const columns = React.useMemo(
        () => [
            {
                field: "id",
                headerName: "ID",
                filterable: true,
                sortable: true,
                minWidth: 320
            },
            {
                field: "createdAt",
                headerName: "Creato il",
                valueFormatter: (params) => {
                    return formatDate(params.value.seconds);
                },
                filterable: true,
                sortable: true,
                minWidth: 145
            },
            {
                field: "itemOf",
                headerName: "Ticket n/n",
                align: "center",
                filterable: false,
                sortable: false,
                hideable: true,
                width: 90
            }, {
                field: "tokenCount",
                headerName: "N° Token",
                align: "center",
                filterable: false,
                sortable: false,
                hideable: true,
                width: 90
            },
            {
                field: "customer",
                headerName: "Acquirente",
                filterable: true,
                sortable: true,
                minWidth: 220
            },
            {
                field: "checkedAt",
                headerName: "Check-In",
                filterable: true,
                sortable: true,
                minWidth: 180,
                valueGetter: (params) => {
                    if (params.row.checked) return params.row.checkedAt.seconds;
                    return false;
                },
                renderCell: (params) => {
                    return <MemoizedRenderCheckedCell {...params} />;
                }
            },
            {
                field: "actions",
                headerName: "Azioni",
                type: "actions",
                filterable: false,
                sortable: false,
                minWidth: 80,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<QrCode/>}
                        label="Dettagli"
                        onClick={() => {
                            handleOnShowDetails(params.id);
                        }}
                    />
                ]
            }
        ],
        [handleOnShowDetails]
    );

    React.useEffect(() => {
        setLoading(true);

        const queryDocs = query(propRefColOnlineTickets, orderBy("createdAt", "desc"));

        const unsubscribe = onSnapshot(
            queryDocs,
            (documentSnapshots) => {
                let _rows =
                    documentSnapshots?.docs?.map((doc) => {
                        return {...doc.data(), id: doc.id};
                    }) ?? [];
                setRows(_rows);
            },
            (e) => {
                enqueueSnackbar(e?.message, {variant: "error"});
            }
        );

        setLoading(false);

        return () => {
            unsubscribe();
        };
    }, [rowsPerPage, enqueueSnackbar, propRefColOnlineTickets]);

    const handlePaginationChange = (changes) => {
        setRowsPerPage(changes.pageSize);
    };

    return (
        <>
            <Box {...dgridWrapperBoxProps}>
                <CustomDataGrid
                    {...dgridProps}
                    stickyHeader
                    disableColumnSelector
                    slots={{
                        noRowsOverlay: DataGridNoResult,
                        loadingOverlay: LinearProgress,
                        toolbar: GridToolbar
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: {debounceMs: 500},
                            printOptions: {
                                disableToolbarButton: true
                            }
                        }
                    }}
                    initialState={{
                        pagination: {paginationModel: {pageSize: 25}},
                        columns: {
                            columnVisibilityModel: {
                                tokenCount: forToken,
                                itemOf: !forToken,
                            }
                        }
                    }}
                    pageSizeOptions={[5, 15, 25]}
                    onPaginationModelChange={handlePaginationChange}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                    }
                    loading={loading}
                    columns={columns}
                    rows={rows}
                />
            </Box>
        </>
    );
}
