import React from "react";
import {Box, Tab, Tabs} from "@mui/material";
import CustomTabPanel, {a11yProps} from "../../components/CustomTabPanel/CustomTabPanel";
import TicketOperator from "./TicketOperator";
import {
    refColOperatorsLogEvent, refColOperatorsLogMasterclass, refColOperatorsLogToken,
    refColTicketsEvent, refColTicketsMasterclass, refColTicketsToken,
    refTicketsData,
    refTicketsMasterclassData, refTicketsTokenData
} from "../../FirebaseProvider/FirebaseProvider";

export default function Scanner() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={handleChange}
                      aria-label="basic tabs example"
                      variant={"scrollable"}
                      scrollButtons={"auto"}>
                    <Tab label="Evento" {...a11yProps(0)} />
                    <Tab label="Masterclass" {...a11yProps(1)} />
                    <Tab label="Token" {...a11yProps(2)} />
                </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
                <TicketOperator
                    propRefTicketsData={refTicketsData}
                    propRefColTickets={refColTicketsEvent}
                    propRefColOperatorsLog={refColOperatorsLogEvent}
                />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
                <TicketOperator
                    propRefTicketsData={refTicketsMasterclassData}
                    propRefColTickets={refColTicketsMasterclass}
                    propRefColOperatorsLog={refColOperatorsLogMasterclass}
                />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
                <TicketOperator
                    propRefTicketsData={refTicketsTokenData}
                    propRefColTickets={refColTicketsToken}
                    propRefColOperatorsLog={refColOperatorsLogToken}
                />
            </CustomTabPanel>
        </>
    );
}
