import {ManageHistory} from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Grid,
    LinearProgress,
    Paper,
    Typography
} from "@mui/material";
import React from "react";
import {
    refColLocalTicketsEvent, refColLocalTicketsMasterclass, refColLocalTicketsToken,
    refColOperatorsLogEvent, refColOperatorsLogMasterclass, refColOperatorsLogToken,
    refColOutgoingMail,
    refColPaypalTransactions,
    refColTicketsEvent, refColTicketsMasterclass, refColTicketsToken, refColVotes
} from "../../FirebaseProvider/FirebaseProvider";
import {getDocsFromServer, query, deleteDoc} from "firebase/firestore";
import {useConfirm} from "material-ui-confirm";
import {useSnackbar} from "notistack";

export default function OtherOptions() {
    const [loading, setLoading] = React.useState(false);
    const confirm = useConfirm();
    const {enqueueSnackbar} = useSnackbar();

    const clearCollection = async (what) => {
        confirm({
            title: "Sicuro di voler ripulire questa lista?",
            description: "Attenzione l'operazione è irreversibile. Vuoi procedere?",
            confirmationText: "Si",
            cancellationText: "Annulla"
        })
            .then(() => {
                setLoading(true);
                return doClearCollection(what);
            })
            .catch((e) => {
                enqueueSnackbar(e?.message, {variant: "error"});
            })
            .finally(() => {
                setLoading(false);
                enqueueSnackbar("Operazione completata!", {variant: "success"});
            });
    };

    const doClearCollection = async (what) => {
        let colRef;
        switch (what) {
            case "email":
                colRef = refColOutgoingMail;
                break;
            case "transactions":
                colRef = refColPaypalTransactions;
                break;

            case "operations-event":
                colRef = refColOperatorsLogEvent;
                break;
            case "tickets-local-event":
                colRef = refColLocalTicketsEvent;
                break;
            case "tickets-online-event":
                colRef = refColTicketsEvent;
                break;

            case "operations-masterclass":
                colRef = refColOperatorsLogMasterclass;
                break;
            case "tickets-local-masterclass":
                colRef = refColLocalTicketsMasterclass;
                break;
            case "tickets-online-masterclass":
                colRef = refColTicketsMasterclass;
                break;

            case "operations-token":
                colRef = refColOperatorsLogToken;
                break;
            case "tickets-local-token":
                colRef = refColLocalTicketsToken;
                break;
            case "tickets-online-token":
                colRef = refColTicketsToken;
                break;

            case "votes":
                colRef = refColVotes;
                break;

            default:
                return;
        }

        const docs = await getDocsFromServer(query(colRef));

        docs.forEach(async (doc) => {
            await deleteDoc(doc.ref);
        });

        setLoading(false);
    };

    return (
        <Paper
            sx={{mt: 2, height: "100%"}}
            elevation={4}
        >
            <Box
                py={2}
                px={1}
                display={"flex"}
                alignItems={"center"}
                flexDirection={"row"}
                component={"div"}
            >
                <Box sx={{color: "secondary.main", marginTop: 1}}>
                    <ManageHistory/>
                </Box>

                <Typography
                    variant="h6"
                    ml={1}
                >
                    Altre operazioni
                </Typography>
            </Box>
            {loading && <LinearProgress variant="indeterminate"/>}

            <Grid
                container
                p={2}
                flexDirection={"column"}
                spacing={4}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Alert severity="info">
                        Queste operazioni sono consigliate durante il setup live
                        dell'applicazione e al termine delle fasi di test.
                    </Alert>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    flexDirection={"row"}
                >
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={3}
                    >
                        <Typography
                            variant="h6"
                            component={"h3"}
                        >
                            Email in uscita
                        </Typography>
                        <Typography variant="body2">
                            Svuotando la tabella delle email non sarà possibile verificare il
                            corretto inoltro delle email passate, ne ripetere nuovi tentativi
                            di invio.
                        </Typography>
                    </Box>
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={1}
                        textAlign={"right"}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                clearCollection("email");
                            }}
                        >
                            Svuota
                        </Button>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={3}
                    >
                        <Typography
                            variant="h6"
                            component={"h3"}
                        >
                            Transazioni PayPal
                        </Typography>
                        <Typography variant="body2">
                            Svuotando la tabella delle transazioni paypal non sarà possibile
                            risalire ai dettagli dell'acquisto. I dettagli (del solo
                            pagamento) rimarrano comunque disponibili sul sito di PayPal.
                        </Typography>
                    </Box>
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={1}
                        textAlign={"right"}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                clearCollection("transactions");
                            }}
                        >
                            Svuota
                        </Button>
                    </Box>
                </Grid>

                <br/>

                <Grid
                    item
                    xs={12}
                    container
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={3}
                    >
                        <Typography
                            variant="h6"
                            component={"h3"}
                        >
                            Operazioni Ticket
                        </Typography>
                        <Typography variant="body2">
                            Svuotando la tabella delle operazioni ticket non sarà possibile
                            risalire ai dettagli del chi e quando ha "smarcato" un ticket.
                        </Typography>
                    </Box>
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={1}
                        textAlign={"right"}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                clearCollection("operations-event");
                            }}
                        >
                            Svuota
                        </Button>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={3}
                    >
                        <Typography
                            variant="h6"
                            component={"h3"}
                        >
                            Ticket Evento OnLine
                        </Typography>
                        <Typography variant="body2">
                            Svuotando la tabella dei ticket evento online... che te lo dico a fare?
                        </Typography>
                    </Box>
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={1}
                        textAlign={"right"}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                clearCollection("tickets-online-event");
                            }}
                        >
                            Svuota
                        </Button>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={3}
                    >
                        <Typography
                            variant="h6"
                            component={"h3"}
                        >
                            Ticket Evento In Loco
                        </Typography>
                        <Typography variant="body2">
                            Svuotando la tabella dei ticket in loco... come sopra...
                        </Typography>
                    </Box>
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={1}
                        textAlign={"right"}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                clearCollection("tickets-local-event");
                            }}
                        >
                            Svuota
                        </Button>
                    </Box>
                </Grid>

                <br/>

                <Grid
                    item
                    xs={12}
                    container
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={3}
                    >
                        <Typography
                            variant="h6"
                            component={"h3"}
                        >
                            Operazioni Ticket Masterclass
                        </Typography>
                        <Typography variant="body2">
                            Svuotando la tabella delle operazioni ticket masterclass non sarà possibile
                            risalire ai dettagli del chi e quando ha "smarcato" un ticket.
                        </Typography>
                    </Box>
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={1}
                        textAlign={"right"}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                clearCollection("operations-masterclass");
                            }}
                        >
                            Svuota
                        </Button>
                    </Box>
                </Grid>

                <Grid
                    item
                    xs={12}
                    container
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={3}
                    >
                        <Typography
                            variant="h6"
                            component={"h3"}
                        >
                            Ticket Masterclass OnLine
                        </Typography>
                        <Typography variant="body2">
                            Svuotando la tabella dei Ticket Masterclass online... che te lo dico a fare?
                        </Typography>
                    </Box>
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={1}
                        textAlign={"right"}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                clearCollection("tickets-online-masterclass");
                            }}
                        >
                            Svuota
                        </Button>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={3}
                    >
                        <Typography
                            variant="h6"
                            component={"h3"}
                        >
                            Ticket Masterclass In Loco
                        </Typography>
                        <Typography variant="body2">
                            Svuotando la tabella dei Ticket Masterclass in loco... come sopra...
                        </Typography>
                    </Box>
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={1}
                        textAlign={"right"}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                clearCollection("tickets-local-masterclass");
                            }}
                        >
                            Svuota
                        </Button>
                    </Box>
                </Grid>

                <br/>

                <Grid
                    item
                    xs={12}
                    container
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={3}
                    >
                        <Typography
                            variant="h6"
                            component={"h3"}
                        >
                            Operazioni Token
                        </Typography>
                        <Typography variant="body2">
                            Svuotando la tabella delle operazioni token non sarà possibile
                            risalire ai dettagli del chi e quando ha "scambiato" token.
                        </Typography>
                    </Box>
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={1}
                        textAlign={"right"}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                clearCollection("operations-token");
                            }}
                        >
                            Svuota
                        </Button>
                    </Box>
                </Grid>

                <Grid
                    item
                    xs={12}
                    container
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={3}
                    >
                        <Typography
                            variant="h6"
                            component={"h3"}
                        >
                            Ticket Token OnLine
                        </Typography>
                        <Typography variant="body2">
                            Svuotando la tabella dei Token online... che te lo dico a fare?
                        </Typography>
                    </Box>
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={1}
                        textAlign={"right"}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                clearCollection("tickets-online-token");
                            }}
                        >
                            Svuota
                        </Button>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={3}
                    >
                        <Typography
                            variant="h6"
                            component={"h3"}
                        >
                            Token In Loco
                        </Typography>
                        <Typography variant="body2">
                            Svuotando la tabella dei Token in loco... come sopra...
                        </Typography>
                    </Box>
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={1}
                        textAlign={"right"}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                clearCollection("tickets-local-token");
                            }}
                        >
                            Svuota
                        </Button>
                    </Box>
                </Grid>

                <br/>

                <Grid
                    item
                    xs={12}
                    container
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={3}
                    >
                        <Typography
                            variant="h6"
                            component={"h3"}
                        >
                            Votazioni
                        </Typography>
                        <Typography variant="body2">
                            Vengono cancellati tutti i riferimenti ticket/votazione
                        </Typography>
                    </Box>
                    <Box
                        component={"div"}
                        flexBasis={0}
                        flexGrow={1}
                        textAlign={"right"}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                clearCollection("votes");
                            }}
                        >
                            Svuota
                        </Button>
                    </Box>
                </Grid>

            </Grid>
        </Paper>
    );
}
