import React from "react";
import DataGridCheckins from "../../components/DataGridCheckins/DataGridCheckins";
import {Box, Tab, Tabs} from "@mui/material";
import CustomTabPanel, {a11yProps} from "../../components/CustomTabPanel/CustomTabPanel";
import {
    refColOperatorsLogEvent, refColOperatorsLogMasterclass, refColOperatorsLogToken
} from "../../FirebaseProvider/FirebaseProvider";

export default function Checkins() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={handleChange}
                      aria-label="basic tabs example"
                      variant={"scrollable"}
                      scrollButtons={"auto"}>
                    <Tab label="Evento" {...a11yProps(0)} />
                    <Tab label="Masterclass" {...a11yProps(1)} />
                    <Tab label="Token" {...a11yProps(2)} />
                </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
                <DataGridCheckins
                    title={"Evento"}
                    dgridPaperProps={{height: "auto"}}
                    dgridWrapperBoxProps={{sx: {height: "77vh", mb: -2}}}
                    dgridProps={{height: "100%"}}
                    propRefColOperatorsLog={refColOperatorsLogEvent}/>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
                <DataGridCheckins
                    title={"Masterclass"}
                    dgridPaperProps={{height: "auto"}}
                    dgridWrapperBoxProps={{sx: {height: "77vh", mb: -2}}}
                    dgridProps={{height: "100%"}}
                    propRefColOperatorsLog={refColOperatorsLogMasterclass}/>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
                <DataGridCheckins
                    title={"Token"}
                    dgridPaperProps={{height: "auto"}}
                    dgridWrapperBoxProps={{sx: {height: "77vh", mb: -2}}}
                    dgridProps={{height: "100%"}}
                    propRefColOperatorsLog={refColOperatorsLogToken}/>
            </CustomTabPanel>
        </>
    );
}
