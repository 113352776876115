import {doc, runTransaction} from "firebase/firestore";
import {enqueueSnackbar} from "notistack";
import {
    dbFirestore
} from "../FirebaseProvider/FirebaseProvider";

export default function useDeleteTicketHook(refColLocalTickets, refTicketsData) {
    const deleteTicket = async (ticketId) => {
        if (!ticketId) return;

        try {
            await runTransaction(dbFirestore, async (transaction) => {
                const refDocToDelete = doc(refColLocalTickets, ticketId);

                const docToDelete = await transaction.get(refDocToDelete);

                if (!docToDelete.exists()) {
                    //errore ticket non trovato
                    return Promise.reject({
                        message: `Record non trovato.`
                    });
                }

                const ticketsDataDoc = await transaction.get(refTicketsData);

                if (!ticketsDataDoc.exists()) {
                    //errore documento di sintesi non trovato
                    return Promise.reject({
                        message: `Errore interno ${refTicketsData.path} non trovato.`
                    });
                }

                const ticketsDataData = ticketsDataDoc.data();

                const docToDeleteData = docToDelete.data();

                /*
                * se si tratta di ticket decrementa/incrementa di 1,
                * se si tratta di token, decrementa/incrementa del numero di token
                */
                const newTicketsSoldOnSite = ticketsDataData.TICKETS_SOLD_ONSITE - (docToDeleteData?.tokenCount || 1);
                const newTicketsLeftOnSite = ticketsDataData.TICKETS_LEFT_ONSITE + (docToDeleteData?.tokenCount || 1);

                transaction.delete(refDocToDelete);

                transaction.update(refTicketsData, {
                    TICKETS_SOLD_ONSITE: newTicketsSoldOnSite,
                    TICKETS_LEFT_ONSITE: newTicketsLeftOnSite
                });
            });
        } catch (err) {
            enqueueSnackbar(err.message, {variant: "error"});
        }
    };

    return deleteTicket;
}
