import React from "react";
import DataGridCheckins from "../../components/DataGridCheckins/DataGridCheckins";
import {Grid, useMediaQuery, useTheme} from "@mui/material";
import DashBoxBar from "../../components/DashBoxBar/DashBoxBar";
import DeskTicketsEmitter from "../../components/DeskTicketsEmitter/DeskTicketsEmitter";
import DataGridTickets from "../../components/DataGridTickets/DataGridTickets";
import PropTypes from "prop-types";

Dashboard.propTypes = {
    propRefColLocalTickets: PropTypes.object.isRequired,
    propRefColOnlineTickets: PropTypes.object.isRequired,
    propRefColOperatorsLog: PropTypes.object.isRequired,
    propRefTicketsData: PropTypes.object.isRequired,
    forToken: PropTypes.bool.isRequired,
    ticketPrefix: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default function Dashboard({
                                      title,
                                      propRefColLocalTickets,
                                      propRefColOnlineTickets,
                                      propRefColOperatorsLog,
                                      propRefTicketsData,
                                      forToken,
                                      ticketPrefix
                                  }) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <>
            <DashBoxBar propRefTicketsData={propRefTicketsData}/>
            <Grid
                container
                spacing={2}
                mt={1}
                alignItems={"stretch"}
                flexWrap={"wrap-reverse"}
            >
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={8}
                >
                    <DataGridCheckins
                        title={title}
                        dgridWrapperBoxProps={{sx: {height: matches ? "60vh" : "77vh"}}}
                        dgridProps={{height: "100%"}}
                        propRefColOperatorsLog={propRefColOperatorsLog}
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                >
                    <DeskTicketsEmitter
                        title={title}
                        propRefColLocalTickets={propRefColLocalTickets}
                        propRefColOnlineTickets={propRefColOnlineTickets}
                        propRefTicketsData={propRefTicketsData}
                        forToken={forToken}
                        ticketPrefix={ticketPrefix}
                    />
                </Grid>
            </Grid>

            <Grid
                container
                mt={2}
            >
                <Grid
                    item
                    xs={12}
                >
                    <DataGridTickets
                        title={title}
                        forToken={forToken}
                        propRefColLocalTickets={propRefColLocalTickets}
                        propRefColOnlineTickets={propRefColOnlineTickets}
                        propRefTicketsData={propRefTicketsData}
                        defaultView="local"
                        dgridWrapperBoxProps={{sx: {height: matches ? "60vh" : "77vh"}}}
                        dgridProps={{height: "100%"}}
                    />
                </Grid>
            </Grid>
        </>
    );
}
