import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import routes from "./const/routes";
import {Link, Route, Routes} from "react-router-dom";
import {APP_NAME, TICKET_PREFIX_EVNT, TICKET_PREFIX_MSTC, TICKET_PREFIX_TKNF} from "./const/const";
import {WineBar} from "@mui/icons-material";
import Checkins from "./pages/Checkins/Checkins";
import Emails from "./pages/Emails/Emails";
import Scanner from "./pages/Scanner/Scanner";
import Paypal from "./pages/Paypal/Paypal";
import Tickets from "./pages/Tickets/Tickets";
import Impostazioni from "./pages/Impostazioni/Impostazioni";
import {Container, useMediaQuery, useTheme} from "@mui/material";
import Dashboard from "./pages/Dashboard/Dashboard";
import {
    firebaseAuth,
    refColLocalTicketsEvent,
    refColLocalTicketsMasterclass,
    refColLocalTicketsToken,
    refColOperatorsLogEvent,
    refColOperatorsLogMasterclass,
    refColOperatorsLogToken, refColTicketsEvent, refColTicketsMasterclass, refColTicketsToken, refTicketsData,
    refTicketsMasterclassData,
    refTicketsTokenData
} from "./FirebaseProvider/FirebaseProvider";
import {signOut} from "firebase/auth";

const drawerWidth = 240;

function Main() {
    const theme = useTheme();
    const isSmallUp = useMediaQuery(theme.breakpoints.up("sm"));

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleLogout = () => {
        signOut(firebaseAuth);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <AppBar
                position="absolute"
                sx={{
                    width: {lg: `${drawerWidth}px`}
                }}
            >
                <Toolbar sx={{bgcolor: "secondary.main"}}>
                    <WineBar sx={{mr: 1, ml: -1}}/>
                    <Typography
                        variant="h6"
                        noWrap
                        component={Link}
                        to="/"
                        sx={{
                            mr: 2,
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none"
                        }}
                    >
                        {APP_NAME}
                    </Typography>
                </Toolbar>
            </AppBar>
            <List sx={{mt: 8}}>
                {routes.map((route) => (
                    <ListItem
                        key={route.label}
                        disablePadding
                    >
                        <ListItemButton
                            component={Link}
                            to={route.path}
                            onClick={() => {
                                setMobileOpen(false);
                            }}
                        >
                            <ListItemIcon>{route.icon}</ListItemIcon>
                            <ListItemText primary={route.label}/>
                        </ListItemButton>
                    </ListItem>
                ))}

                <ListItem>
                    <ListItemButton
                        onClick={handleLogout}>
                        <ListItemText>Logout</ListItemText>
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    );

    return (
        <Box sx={{display: "flex"}}>
            <CssBaseline/>
            <AppBar
                position="fixed"
                sx={{
                    width: {lg: `calc(100% - ${drawerWidth}px)`},
                    ml: {lg: `${drawerWidth}px`}
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{mr: 2, display: {lg: "none"}}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <WineBar sx={{mr: 1, ml: -1, display: {lg: "none"}}}/>
                    <Typography
                        variant="h6"
                        noWrap
                        component={Link}
                        to="/"
                        sx={{
                            mr: 2,
                            display: {lg: "none"},
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none"
                        }}
                    >
                        {APP_NAME}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{width: {lg: drawerWidth}, flexShrink: {md: 0}}}
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true
                    }}
                    sx={{
                        display: {xs: "block", lg: "none"},
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth
                        }
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: {xs: "none", lg: "block"},
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth
                        }
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>

            <Container
                component={"main"}
                sx={{
                    flexGrow: 1,
                    px: isSmallUp ? 1.5 : 0.5,
                    width: {lg: `calc(100% - ${drawerWidth}px)`},
                    pb: 8
                }}
                maxWidth="lg"
            >
                <Toolbar sx={{mt: 3}}/>
                <Routes>
                    <Route
                        path="/"
                        element={<Dashboard
                            title="Evento"
                            propRefColLocalTickets={refColLocalTicketsEvent}
                            propRefColOnlineTickets={refColTicketsEvent}
                            propRefColOperatorsLog={refColOperatorsLogEvent}
                            propRefTicketsData={refTicketsData}
                            forToken={false}
                            ticketPrefix={TICKET_PREFIX_EVNT}
                        />}
                    />
                    <Route
                        path="/dashboard-evento"
                        element={<Dashboard
                            title="Evento"
                            propRefColLocalTickets={refColLocalTicketsEvent}
                            propRefColOnlineTickets={refColTicketsEvent}
                            propRefColOperatorsLog={refColOperatorsLogEvent}
                            propRefTicketsData={refTicketsData}
                            forToken={false}
                            ticketPrefix={TICKET_PREFIX_EVNT}
                        />}
                    />
                    <Route
                        path="/dashboard-masterclass"
                        element={<Dashboard title="Masterclass"
                                            propRefColLocalTickets={refColLocalTicketsMasterclass}
                                            propRefColOnlineTickets={refColTicketsMasterclass}
                                            propRefColOperatorsLog={refColOperatorsLogMasterclass}
                                            propRefTicketsData={refTicketsMasterclassData}
                                            forToken={false}
                                            ticketPrefix={TICKET_PREFIX_MSTC}
                        />}
                    />
                    <Route
                        path="/dashboard-token"
                        element={<Dashboard title="Token"
                                            propRefColLocalTickets={refColLocalTicketsToken}
                                            propRefColOnlineTickets={refColTicketsToken}
                                            propRefColOperatorsLog={refColOperatorsLogToken}
                                            propRefTicketsData={refTicketsTokenData}
                                            forToken={true}
                                            ticketPrefix={TICKET_PREFIX_TKNF}
                        />}
                    />
                    <Route
                        path="/scanner"
                        element={<Scanner/>}
                    />
                    <Route
                        path="/checkins"
                        element={<Checkins/>}
                    />
                    <Route
                        path="/email"
                        element={<Emails/>}
                    />
                    <Route
                        path="/paypal"
                        element={<Paypal/>}
                    />
                    <Route
                        path="/tickets"
                        element={<Tickets/>}
                    />
                    <Route
                        path="/impostazioni"
                        element={<Impostazioni/>}
                    />
                </Routes>
            </Container>
        </Box>
    );
}

export default Main;
