import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import React from "react";

export default function ModalDetails({ open, content, handleClose, title }) {
  return (
    <Dialog
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent sx={{ fontSize: "80%", color: "primary.main" }}>
        {content}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          autoFocus
        >
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
}
