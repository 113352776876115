import React from "react";
import DataGridPaypal from "../../components/DataGridPaypal/DataGridPaypal";

export default function Paypal() {
  return (
    <DataGridPaypal
      dgridWrapperBoxProps={{ sx: { height: "77vh", mb: -2 } }}
      dgridProps={{ height: "100%" }}
    />
  );
}
