const APP_NAME = "Wine Not?";

const TICKET_PREFIX_EVNT = "EVNT";
const TICKET_PREFIX_MSTC = "MSTC";
const TICKET_PREFIX_TKNF = "TKNF";

const PRODUCT_TICKETS = 1;
const PRODUCT_MASTERCLASS = 2;
const PRODUCT_TOKEN = 3;

const PAYMENT_TYPE_CASH = "cash";
const PAYMENT_TYPE_CARD = "card";

export {
    APP_NAME,
    TICKET_PREFIX_TKNF,
    TICKET_PREFIX_MSTC,
    TICKET_PREFIX_EVNT,
    PRODUCT_TICKETS,
    PRODUCT_MASTERCLASS,
    PRODUCT_TOKEN,
    PAYMENT_TYPE_CASH,
    PAYMENT_TYPE_CARD
};
