import React from "react";
import { refColPaypalTransactions } from "../../FirebaseProvider/FirebaseProvider";
import { GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { orderBy, query, onSnapshot } from "firebase/firestore";
import { Box, LinearProgress, Link, Paper, Typography } from "@mui/material";
import DataGridNoResult from "../DataGridNoResult/DataGridNoResult";
import { FaPaypal } from "react-icons/fa";
import CustomDataGrid from "../CustomDataGrid/CustomDataGrid";
import { Loupe, OpenInNew } from "@mui/icons-material";
import ModalDetails from "../ModalDetails/ModalDetails";
import { useSnackbar } from "notistack";

export default function DataGridPaypal({ dgridProps, dgridWrapperBoxProps }) {
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setLoading] = React.useState(true);
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const [detailsContent, setDetailsContent] = React.useState(null);

  const onShowDetail = React.useCallback(
    (id) => () => {
      if (!id) return;

      const details = rows.find((row) => {
        return row.id === id;
      });

      if (details) {
        setDetailsContent(
          <pre
            style={{
              width: "100%",
              overflow: "auto",
              height: "400px",
              backgroundColor: "#ddd",
              padding: "8px",
              border: "1px solid #ccc"
            }}
          >
            {JSON.stringify(details, null, 4)}
          </pre>
        );
      } else {
        setDetailsContent("Dettagli non trovati");
      }

      setDetailsOpen(true);
    },
    [rows]
  );

  const handleCloseDetails = () => {
    setDetailsOpen(false);
    setDetailsContent(null);
  };

  const columns = React.useMemo(
    () => [
      {
        field: "id",
        headerName: "ID Transazione",
        filterable: false,
        sortable: false,
        minWidth: 220
      },
      {
        field: "create_time",
        headerName: "Effettuata il",
        valueGetter: (params) =>
          new Intl.DateTimeFormat("it", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit"
          }).format(new Date(params.row.create_time)),
        filterable: false,
        sortable: true,
        minWidth: 145
      },
      {
        field: "acquirente",
        headerName: "Acquirente",
        searchable: true,
        valueGetter: (params) => params.row.winenotData?.nominativo,
        filterable: false,
        sortable: false,
        minWidth: 200
      },
      {
        field: "qty",
        headerName: "Qta",
        type: "number",
        valueGetter: (params) => params.row.winenotData?.qty,
        filterable: false,
        sortable: false,
        minWidth: 80,
        align: "center",
        headerAlign: "center"
      },
      {
        field: "telefono",
        headerName: "Telefono",
        valueGetter: (params) => {
          return params.row.winenotData?.telefono;
        },
        filterable: false,
        sortable: false,
        minWidth: 145
      },
      {
        field: "email",
        headerName: "Email",
        renderCell: (params) => [
          <Link
            href={`mailto:${params.row.winenotData?.email}`}
            variant="caption"
            target="_blank"
            key={`${params.id}-lnk-mail`}
          >
            {params.row.winenotData?.email}
          </Link>
        ],
        minWidth: 200
      },
      {
        field: "actions",
        headerName: "Azioni",
        type: "actions",
        filterable: false,
        sortable: false,
        minWidth: 110,
        getActions: (params) => [
          <GridActionsCellItem
            key={`${params.id}-modal-details`}
            icon={<Loupe />}
            label="Dettagli"
            onClick={onShowDetail(params.id)}
          />,
          <GridActionsCellItem
            key={`${params.id}-lnk-ppl`}
            icon={<OpenInNew />}
            label="Mostra su PayPal"
            href={params.row.links[0]?.href}
            target="_blank"
            LinkComponent={Link}
          />
        ]
      }
    ],
    [onShowDetail]
  );

  React.useEffect(() => {
    setLoading(true);

    const queryDocs = query(
      refColPaypalTransactions,
      orderBy("create_time", "desc")
    );

    const unsubscribe = onSnapshot(
      queryDocs,
      (documentSnapshots) => {
        let _rows = documentSnapshots?.docs?.map((doc) => doc.data()) ?? [];
        setRows(_rows);
      },
      (e) => {
        enqueueSnackbar(e?.message, { variant: "error" });
      }
    );

    setLoading(false);

    return () => {
      unsubscribe();
    };
  }, [rowsPerPage, enqueueSnackbar]);

  const handlePaginationChange = (changes) => {
    setRowsPerPage(changes.pageSize);
  };

  return (
    <>
      <Paper
        sx={{ height: "auto" }}
        elevation={4}
      >
        <Box
          p={2}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"row"}
          component={"div"}
        >
          <Box sx={{ color: "secondary.main", marginTop: 1 }}>
            <FaPaypal />
          </Box>

          <Typography
            variant="h6"
            ml={1}
          >
            Transazioni Paypal
          </Typography>
        </Box>
        <Box {...dgridWrapperBoxProps}>
          <CustomDataGrid
            {...dgridProps}
            stickyHeader
            disableColumnSelector
            slots={{
              noRowsOverlay: DataGridNoResult,
              loadingOverlay: LinearProgress,
              toolbar: GridToolbar
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                printOptions: {
                  disableToolbarButton: true
                }
              }
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } }
            }}
            pageSizeOptions={[5, 15, 25]}
            onPaginationModelChange={handlePaginationChange}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            loading={loading}
            columns={columns}
            rows={rows}
          />
        </Box>
      </Paper>
      <ModalDetails
        open={detailsOpen ? true : false}
        handleClose={handleCloseDetails}
        content={detailsContent}
        title={"Dettagli transazione"}
      />
    </>
  );
}
