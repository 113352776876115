import React from "react";
import {CreditCard, LocalAtm, PointOfSale} from "@mui/icons-material";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Grid,
    Paper,
    Typography
} from "@mui/material";
import {doc, runTransaction} from "firebase/firestore";
import {IoTicketOutline} from "react-icons/io5";
import {
    dbFirestore
} from "../../FirebaseProvider/FirebaseProvider";
import {v4 as uuidv4} from "uuid";
import useAuthContext from "../../context/AuthContext/useAuthContext";
import PropTypes from "prop-types";
import {PAYMENT_TYPE_CARD, PAYMENT_TYPE_CASH} from "../../const/const";
import TokenRefunder from "./TokenRefunder";

const ABORT_COUNT_DOWN_SEC = 5;

DeskTicketsEmitter.propTypes = {
    propRefColLocalTickets: PropTypes.object.isRequired,
    propRefTicketsData: PropTypes.object.isRequired,
    ticketPrefix: PropTypes.string.isRequired,
    forToken: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
};

export default function DeskTicketsEmitter({
                                               title,
                                               propRefColLocalTickets,
                                               propRefTicketsData,
                                               ticketPrefix,
                                               forToken
                                           }) {
    const {user} = useAuthContext();

    const [error, setError] = React.useState(null);
    const [disableButtons, setDisableButtons] = React.useState(false);
    const [disabledPaymentTypeButton, setDisabledPaymentTypeButton] = React.useState(true);
    const [countdown, setCountdown] = React.useState(ABORT_COUNT_DOWN_SEC);
    const [showAlert, setShowAlert] = React.useState(false);

    const intervalRef = React.useRef();
    const qtyReadyToSell = React.useRef(0);

    const abort = () => {
        clearInterval(intervalRef.current);
        setDisableButtons(false);
        setDisabledPaymentTypeButton(true);
        setShowAlert(false);
        setCountdown(ABORT_COUNT_DOWN_SEC);
    };

    const emitTickets = async (paymentType) => {
        let toScale = qtyReadyToSell.current;

        try {
            await runTransaction(dbFirestore, async (transaction) => {
                const lottoRaw = uuidv4().split("-");
                const lotto = lottoRaw[lottoRaw.length - 1];

                const ticketsDataDoc = await transaction.get(propRefTicketsData);

                if (!ticketsDataDoc.exists()) {
                    //errore documento di sintesi non trovato
                    return Promise.reject({
                        message: `Errore interno ${propRefTicketsData.path} non trovato.`
                    });
                }

                const ticketsDataData = ticketsDataDoc.data();

                if (ticketsDataData.TICKETS_LEFT_ONSITE < toScale) {
                    return Promise.reject({
                        message: "Disponibilità insufficiente!"
                    });
                }

                const newTicketsSoldOnSite =
                    ticketsDataData.TICKETS_SOLD_ONSITE + toScale;
                const newTicketsLeftOnSite =
                    ticketsDataData.TICKETS_LEFT_ONSITE - toScale;

                //se si tratta di token emetto un solo record
                if (forToken) {
                    const ticketId = `${ticketPrefix}-${uuidv4().toUpperCase()}`;
                    let ticket = {
                        id: ticketId,
                        createdAt: new Date(),
                        createdBy: user?.email || "System",
                        tokenCount: toScale,
                        lotto: lotto,
                        paymentType: paymentType,
                    };
                    transaction.set(doc(propRefColLocalTickets, ticketId), ticket);
                } else {
                    //se si tratta di ticket emetto un record per ogni biglietto
                    for (let i = 1; i <= toScale; i++) {
                        const ticketId = `${ticketPrefix}-${uuidv4().toUpperCase()}`;

                        let ticket = {
                            id: ticketId,
                            createdAt: new Date(),
                            createdBy: user?.email || "System",
                            itemOf: `${i}/${toScale}`,
                            lotto: lotto,
                            paymentType: paymentType,
                        };

                        transaction.set(doc(propRefColLocalTickets, ticketId), ticket);
                    }
                }
                transaction.update(propRefTicketsData, {
                    TICKETS_SOLD_ONSITE: newTicketsSoldOnSite,
                    TICKETS_LEFT_ONSITE: newTicketsLeftOnSite
                });
            });
        } catch (e) {
            console.error(e);
            setError(e);
        }

        abort();
    };

    const onTicketSell = (qty) => {
        qtyReadyToSell.current = qty;
        setDisableButtons(true);
        setDisabledPaymentTypeButton(false);
        setShowAlert(true);
    };

    const onPaymentTypeSelected = (paymentType) => {
        setDisabledPaymentTypeButton(true);
        setError(null);
        intervalRef.current = setInterval(() => {
            setCountdown((p) => {
                if (p === 0) {
                    emitTickets(paymentType);
                    return 0;
                }
                return p - 1;
            });
        }, 1000);
    }

    let buttonOptions;

    if (forToken) {
        buttonOptions = Array.from([5, 10, 15, 20, 25, 30, 35, 40, 45, PAYMENT_TYPE_CASH, 50, PAYMENT_TYPE_CARD]);
    } else {
        buttonOptions = Array.from([1, 2, 3, 4, 5, 6, 7, 8, 9, PAYMENT_TYPE_CASH, 10, PAYMENT_TYPE_CARD]);
    }

    return (
        <Paper
            elevation={4}
            sx={{height: "100%"}}
        >
            <Box
                p={1}
                display={"flex"}
                alignItems={"center"}
                flexDirection={"row"}
                component={"div"}
            >
                <Box sx={{color: "secondary.main", marginTop: 1}}>
                    <PointOfSale/>
                </Box>

                <Typography
                    variant="h6"
                    ml={1}
                >
                    Cassa {title}
                </Typography>
            </Box>
            <Grid
                container
                spacing={1}
                p={2}
                justifyContent={"space-around"}
                alignItems={"stretch"}
            >
                {buttonOptions.map((i) => {
                    if (isNaN(i)) {
                        return <Grid
                            item
                            key={i}
                        >
                            <Button
                                startIcon={i === PAYMENT_TYPE_CASH ? <LocalAtm/> : <CreditCard/>}
                                variant="contained"
                                color={i === PAYMENT_TYPE_CASH ? "secondary" : "success"}
                                size="medium"
                                onClick={() => {
                                    onPaymentTypeSelected(i)
                                }}
                                disabled={disabledPaymentTypeButton}
                            >
                                {i}
                            </Button>
                        </Grid>
                    }
                    return (
                        <Grid
                            item
                            key={i}
                        >
                            <Button
                                startIcon={<IoTicketOutline/>}
                                variant="contained"
                                color="primary"
                                size="medium"
                                onClick={() => {
                                    onTicketSell(i);
                                }}
                                disabled={disableButtons}
                            >
                                {i}
                                <br/>
                                {forToken ? "Token" : "Ticket"}
                            </Button>
                        </Grid>
                    );
                })}
                <Grid
                    item
                    xs={12}
                    mt={2}
                >
                    {showAlert && (
                        <Alert
                            severity="warning"
                            closeText="Annulla"
                            action={
                                <Button
                                    color="warning"
                                    variant={"contained"}
                                    size="small"
                                    onClick={abort}
                                >
                                    Annulla
                                </Button>
                            }
                        >
                            {disabledPaymentTypeButton ?
                                <><AlertTitle>{countdown} secondi per annullare!</AlertTitle>
                                    Emissione
                                    di <strong>{qtyReadyToSell.current}</strong> {forToken ? "Token" : "Ticket"} in
                                    corso...</>
                                :
                                <><AlertTitle>Metodo di pagamento?</AlertTitle>
                                    Selezionare un metodo di pagamento!</>
                            }
                        </Alert>
                    )}
                    {error && (
                        <Alert severity="error">
                            <AlertTitle>Errore</AlertTitle>
                            {error?.message}
                        </Alert>
                    )}
                    {!error && !showAlert && (
                        <Alert severity="info">
                            <AlertTitle>Emetti {forToken ? "Token" : "Ticket"}</AlertTitle>
                            Usa la pulsantiera per emettere {forToken ? "Token" : "Ticket"} in loco.
                        </Alert>
                    )}

                    {forToken && <TokenRefunder/>}

                </Grid>
            </Grid>
        </Paper>
    );
}

