import React from "react";
import {
    Alert,
    AlertTitle,
    Button,
    Container,
    Grid,
    Typography
} from "@mui/material";

import CamScanner from "./CamScanner";
import {Pause, QrCode} from "@mui/icons-material";
import {doc, runTransaction} from "firebase/firestore";
import {
    dbFirestore,
    timeStampFormat
} from "../../FirebaseProvider/FirebaseProvider";
import useSound from "use-sound";
import soundError from "../../audio/error.mp3";
import soundSuccess from "../../audio/success.mp3";
import useAuthContext from "../../context/AuthContext/useAuthContext";
import PropTypes from "prop-types";
import {PRODUCT_TOKEN} from "../../const/const";

TicketOperator.propTypes = {
    propRefColOperatorsLog: PropTypes.object.isRequired,
    propRefColTickets: PropTypes.object.isRequired,
    propRefTicketsData: PropTypes.object.isRequired,
};

export default function TicketOperator({
                                           propRefColOperatorsLog,
                                           propRefColTickets,
                                           propRefTicketsData
                                       }) {
    const {user} = useAuthContext();
    const [inPause, setInPause] = React.useState(false);
    const [loadingTicketInfo, setLoadingTicketInfo] = React.useState(false);
    const [ticketCode, setTicketCode] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [lastTicketData, setLastTicketData] = React.useState(null);

    const [playError] = useSound(soundError);
    const [playSuccess] = useSound(soundSuccess);

    const onSuccessScan = (result) => {
        setInPause(true);
        if (result?.text) {
            setLoadingTicketInfo(true);
            setTicketCode(result.text);
        }
    };

    const scan = () => {
        setInPause(!inPause);
        setError(null);
        setLastTicketData(null);
        setTicketCode(!inPause ? ticketCode : null);
    };

    const fireError = React.useCallback(
        (error) => {
            setError(error);
            playError();
            setLoadingTicketInfo(false);
            if (navigator.vibrate) {
                navigator.vibrate([100, 30, 100, 30, 100]);
            }
        },
        [playError]
    );

    const fireSuccess = React.useCallback(
        (ticketData) => {
            playSuccess();
            setLastTicketData(ticketData);
            setLoadingTicketInfo(false);
            if (navigator.vibrate) {
                navigator.vibrate(100);
            }
        },
        [playSuccess]
    );

    React.useEffect(() => {
        if (!user) {
            setLoadingTicketInfo(false);
            return;
        }

        if (!ticketCode) {
            setLoadingTicketInfo(false);
            return;
        }

        let _ticketData = null;

        const processTicket = async () => {
            try {
                const logRef = doc(propRefColOperatorsLog);

                const ticketRef = doc(propRefColTickets, ticketCode);

                await runTransaction(dbFirestore, async (transaction) => {
                    const ticketDoc = await transaction.get(ticketRef);

                    if (!ticketDoc.exists()) {
                        //errore non esiste il ticket
                        return Promise.reject({message: "QR Code non trovato su DB!"});
                    }

                    const ticketData = ticketDoc.data();

                    if (ticketData.checked) {
                        //errore il ticket risulta già smarcato
                        return Promise.reject({
                            message: "QR Code già smarcato!",
                            details: ticketData
                        });
                    }

                    const ticketsDataDoc = await transaction.get(propRefTicketsData);

                    if (!ticketsDataDoc.exists()) {
                        //errore documento di sintesi non trovato
                        return Promise.reject({
                            message: `Errore interno ${propRefTicketsData.path} non trovato.`
                        });
                    }
                    const ticketsDataData = ticketsDataDoc.data();

                    let qtyToIncrement = 1;

                    if (ticketData.product === PRODUCT_TOKEN) {
                        qtyToIncrement = Number(ticketData?.tokenCount)
                    }

                    const newCheckedCount = ticketsDataData.TICKETS_CHECKED + qtyToIncrement;

                    //aggiorna campo checked su ticket
                    transaction.update(ticketRef, {
                        checked: true,
                        checkedBy: user.email,
                        checkedAt: new Date()
                    });

                    //aggiorna conteggio tickets smarcati
                    transaction.update(propRefTicketsData, {
                        TICKETS_CHECKED: newCheckedCount
                    });

                    //inserisci log
                    transaction.set(logRef, {
                        who: user.email,
                        at: new Date(),
                        ticketId: ticketData.id,
                        customer: ticketData.customer,
                        product: ticketData.product
                    });

                    _ticketData = ticketData;
                });

                fireSuccess(_ticketData);
            } catch (e) {
                fireError(e);
            }
        };

        processTicket();
    }, [ticketCode,
        user,
        fireSuccess,
        fireError,
        propRefColOperatorsLog,
        propRefColTickets,
        propRefTicketsData]);

    return (
        <Container maxWidth={"xs"}>
            <Grid
                container
                flexDirection={"column"}
                spacing={2}
                wrap="nowrap"
            >
                <Grid item>
                    <ScanButton
                        onClick={scan}
                        inPause={inPause}
                    />
                </Grid>

                <Grid item>
                    <CamScanner
                        onSuccessScan={onSuccessScan}
                        inPause={inPause}
                        style={{height: inPause ? 0 : "auto", width: "100%"}}
                    />
                    <Typography
                        variant="overline"
                        textAlign={"center"}
                        component={"div"}
                        overflow={"auto"}
                    >
                        {ticketCode}
                    </Typography>
                </Grid>
                {loadingTicketInfo && (
                    <Grid item>
                        <Alert severity="info">
                            <AlertTitle>Recupero info QR Code...</AlertTitle>
                        </Alert>
                    </Grid>
                )}
                {error && (
                    <Grid item>
                        <Alert severity="error">
                            <AlertTitle>{error.message}</AlertTitle>
                            {error?.details && (
                                <p>
                                    Da <strong>{error.details?.checkedBy}</strong>
                                    <br/>
                                    {timeStampFormat(
                                        new Date(error.details?.checkedAt.seconds * 1000)
                                    )}
                                    <br/>
                                    Ticket <strong>{error.details?.itemOf}</strong>
                                    <br/>
                                    Acquistato da <strong>{error.details?.customer}</strong>
                                    <br/>
                                    il{" "}
                                    {timeStampFormat(
                                        new Date(error.details?.createdAt.seconds * 1000)
                                    )}
                                </p>
                            )}
                        </Alert>
                    </Grid>
                )}
                {lastTicketData && (
                    <>
                        <Grid item>
                            <img
                                style={{width: "50%", display: "block", margin: "0 auto"}}
                                src={lastTicketData.qrCode}
                                alt={lastTicketData.id}
                                title={lastTicketData.id}
                            />
                        </Grid>
                        <Grid item>
                            <Alert severity="success">
                                <AlertTitle>QR Code Valido!</AlertTitle>
                                {lastTicketData.product === PRODUCT_TOKEN && (
                                    <h4>Vale {lastTicketData.tokenCount} Token</h4>
                                )}
                                <p>
                                    Dite grazie a <strong>{lastTicketData.customer}</strong>!
                                    <br/>
                                </p>
                            </Alert>
                        </Grid>
                    </>
                )}
            </Grid>
        </Container>
    );
}

const ScanButton = ({inPause, onClick}) => {
    return (
        <Button
            fullWidth
            onClick={onClick}
            color={inPause ? "success" : "warning"}
            variant="contained"
            size="large"
            startIcon={inPause ? <QrCode/> : <Pause/>}
        >
            {inPause ? "Scan" : "Pausa"}
        </Button>
    );
};
