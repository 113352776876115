import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import AuthContext from "./AuthContext";
import { firebaseAuth } from "../../FirebaseProvider/FirebaseProvider";

const AuthProvider = ({ children }) => {
  const [user, loading, error] = useAuthState(firebaseAuth);

  const logout = () => {
    return firebaseAuth.signOut();
  };

  let values = {
    user: user,
    authError: error,
    authLoading: loading,
    logout: logout
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
