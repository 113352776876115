import {
  Autorenew,
  Check,
  Error,
  HourglassEmpty,
  Memory
} from "@mui/icons-material";

const STATUS_TYPE = {
  PENDING: {
    label: "Pending",
    color: "info",
    icon: <HourglassEmpty />
  },
  PROCESSING: {
    label: "Processing",
    color: "primary",
    icon: <Memory />
  },
  RETRY: {
    label: "Retry",
    color: "warning",
    icon: <Autorenew />
  },
  SUCCESS: {
    label: "Success",
    color: "success",
    icon: <Check />
  },
  ERROR: {
    label: "Error",
    color: "error",
    icon: <Error />
  }
};

export { STATUS_TYPE };
