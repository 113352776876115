import React from "react";
import DataGridEmail from "../../components/DataGridEmail/DataGridEmail";

export default function Emails() {
  return (
    <DataGridEmail
      dgridWrapperBoxProps={{ sx: { height: "77vh", mb: -2 } }}
      dgridProps={{ height: "100%" }}
    />
  );
}
