import {
    BookOnline,
    ConfirmationNumber,
    Sell,
    TransferWithinAStation
} from "@mui/icons-material";
import {Grid} from "@mui/material";
import {useDocumentData} from "react-firebase-hooks/firestore";

import React from "react";

import DashBox from "../../components/DashBox/DashBox";
import PropTypes from "prop-types";

DashBoxBar.propTypes = {
    propRefTicketsData: PropTypes.object.isRequired,
};

export default function DashBoxBar({propRefTicketsData}) {
    const [value, loading, error] = useDocumentData(propRefTicketsData, {
        snapshotListenOptions: {includeMetadataChanges: true}
    });

    //TODO in questi calcoli non vengono considerati gli storni dei token

    const checked = Number(value?.TICKETS_CHECKED || 0) + Number(value?.TICKETS_SOLD_ONSITE || 0);

    const entrate =
        (Number(value?.TICKETS_SOLD_ONLINE || 0) * Number(value?.TICKETS_PRICE || 0))
        +
        (Number(value?.TICKETS_SOLD_ONSITE || 0) * Number(value?.TICKETS_PRICE_ONSITE || 0));

    const totVenduti = (value?.TICKETS_SOLD_ONLINE || 0) + (value?.TICKETS_SOLD_ONSITE || 0);

    const _percPresentati = checked === 0 && totVenduti === 0
            ? 0
            : (checked * 100) / totVenduti;

    const percPresentati = Math.floor(_percPresentati * 100) / 100;

    return (
        <Grid
            container
            spacing={2}
            alignItems={"stretch"}
            mt={-4}
        >
            <Grid
                xs={12}
                sm={6}
                md={3}
                item
            >
                <DashBox
                    title={`Disponibili (${value?.TICKETS_TOTAL || 0})`}
                    value={[
                        {count: value?.TICKETS_LEFT_ONLINE || 0, label: "Online"},
                        {count: value?.TICKETS_LEFT_ONSITE || 0, label: "In Loco"}
                    ]}
                    icon={<ConfirmationNumber/>}
                    loading={loading}
                    error={error}
                    multiple={true}
                />
            </Grid>
            <Grid
                xs={12}
                sm={6}
                md={3}
                item
            >
                <DashBox
                    title={"Venduti"}
                    value={[
                        {count: value?.TICKETS_SOLD_ONLINE || 0, label: "Online"},
                        {count: value?.TICKETS_SOLD_ONSITE || 0, label: "In Loco"},
                        {
                            count: totVenduti,
                            label: "Tot"
                        }
                    ]}
                    icon={<BookOnline/>}
                    loading={loading}
                    error={error}
                    multiple={true}
                />
            </Grid>
            <Grid
                xs={12}
                sm={6}
                md={3}
                item
            >
                <DashBox
                    title={"Presentati"}
                    value={[
                        {count: checked || 0, label: "Entrati"},

                        {count: `${percPresentati}%`, label: "Percentuale"}
                    ]}
                    icon={<TransferWithinAStation/>}
                    loading={loading}
                    error={error}
                    multiple={true}
                />
            </Grid>
            <Grid
                xs={12}
                sm={6}
                md={3}
                item
            >
                <DashBox
                    title={"Entrate"}
                    value={entrate}
                    icon={<Sell/>}
                    loading={loading}
                    error={error}
                    currency={true}
                    variant={"h3"}
                />
            </Grid>
        </Grid>
    );
}
