import React from "react";
import {orderBy, query, onSnapshot} from "firebase/firestore";
import {Box, Chip, LinearProgress} from "@mui/material";
import {CreditCard, Delete, LocalAtm, Loupe} from "@mui/icons-material";
import DataGridNoResult from "../DataGridNoResult/DataGridNoResult";
import CustomDataGrid from "../CustomDataGrid/CustomDataGrid";
import {GridActionsCellItem, GridToolbar} from "@mui/x-data-grid";
import {useSnackbar} from "notistack";

import {useConfirm} from "material-ui-confirm";
import useDeleteTicketHook from "../../hooks/useDeleteTicketHook";
import PropTypes from "prop-types";
import {PAYMENT_TYPE_CASH} from "../../const/const";

const formatDate = (seconds) => {
    return new Intl.DateTimeFormat("it", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
    }).format(new Date(seconds * 1000));
};

const PaymentTypeCell = ({value}) => {
    return (
        <Chip
            icon={value === PAYMENT_TYPE_CASH ? <LocalAtm/> : <CreditCard/>}
            label={value}
            color={value === PAYMENT_TYPE_CASH ? "secondary" : "success"}
            size="small"
        />
    );
};

const MemoizedPaymentTypeCell = React.memo(
    PaymentTypeCell,
    (prev, next) => {
        return prev.value === next.value;
    }
);

DataGridTicketsLocal.propTypes = {
    dgridProps: PropTypes.object,
    dgridWrapperBoxProps: PropTypes.object,
    onShowDetails: PropTypes.func,
    propRefColLocalTickets: PropTypes.object.isRequired,
    propRefTicketsData: PropTypes.object.isRequired,
    forToken: PropTypes.bool.isRequired,
};

export default function DataGridTicketsLocal({
                                                 dgridProps,
                                                 dgridWrapperBoxProps,
                                                 onShowDetails,
                                                 propRefColLocalTickets,
                                                 propRefTicketsData,
                                                 forToken
                                             }) {
    const {enqueueSnackbar} = useSnackbar();
    const confirm = useConfirm();
    const doDeleteTicket = useDeleteTicketHook(propRefColLocalTickets, propRefTicketsData);

    const [rows, setRows] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [loading, setLoading] = React.useState(true);

    const deleteTicket = React.useCallback(
        (ticketId) => {
            confirm({
                title: "Attenzione",
                description: <>Sicuro di voler cancellare questo Ticket?</>,
                confirmationText: "Si",
                cancellationText: "Annulla"
            })
                .then(() => {
                    setLoading(true);
                    return doDeleteTicket(ticketId);
                })
                .catch((e) => {
                    console.error(e);
                    enqueueSnackbar(
                        `Problemi durante la cancellazione del Ticket. ${e.message}`,
                        {variant: "error"}
                    );
                })
                .then(() => {
                    enqueueSnackbar(`Ticket eliminato!`, {variant: "success"});
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [doDeleteTicket, enqueueSnackbar, confirm]
    );

    const handleOnShowDetails = React.useCallback(
        (ticketId) => {
            if (!ticketId) {
                enqueueSnackbar("ID Ticket non pervenuto", {variant: "warning"});
                return;
            }

            const details = rows.find((row) => {
                return row.id === ticketId;
            });

            if (!details) {
                enqueueSnackbar("Dettagli non disponibili!", {variant: "warning"});
                return;
            }

            onShowDetails(
                <div>
          <pre
              style={{
                  width: "100%",
                  overflow: "auto",
                  height: "400px",
                  backgroundColor: "#ddd",
                  padding: "8px",
                  border: "1px solid #ccc"
              }}
          >
            {JSON.stringify(details, null, 4)}
          </pre>
                </div>
            );
        },
        [rows, onShowDetails, enqueueSnackbar]
    );

    const columns = React.useMemo(
        () => [
            {
                field: "id",
                headerName: "ID",
                filterable: true,
                sortable: true,
                minWidth: 320
            },
            {
                field: "createdAt",
                headerName: "Creato il",
                valueFormatter: (params) => {
                    return formatDate(params.value.seconds);
                },
                filterable: true,
                sortable: true,
                minWidth: 145
            },
            {
                field: "createdBy",
                headerName: "Creato da",
                filterable: true,
                sortable: true,
                minWidth: 200
            },
            {
                field: "lotto",
                headerName: "Lotto",
                filterable: true,
                sortable: true,
                minWidth: 130
            },
            {
                field: "itemOf",
                headerName: "Ticket n/n",
                align: "center",
                filterable: false,
                sortable: false,
                hideable: true,
                minWidth: 90
            },
            {
                field: "tokenCount",
                headerName: "N° Token",
                align: "center",
                filterable: false,
                sortable: false,
                hideable: true,
                minWidth: 90
            },
            {
                field: "paymentType",
                headerName: "Pagamento",
                align: "center",
                filterable: true,
                sortable: true,
                minWidth: 90,
                renderCell: (params) => {
                    return <MemoizedPaymentTypeCell {...params} />;
                }
            },
            {
                field: "actions",
                headerName: "Azioni",
                type: "actions",
                filterable: false,
                sortable: false,
                minWidth: 110,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<Loupe/>}
                        label="Dettagli"
                        onClick={() => {
                            handleOnShowDetails(params.id);
                        }}
                    />,
                    <GridActionsCellItem
                        icon={<Delete/>}
                        label="Annulla"
                        onClick={() => {
                            deleteTicket(params.id);
                        }}
                    />
                ]
            }
        ],
        [handleOnShowDetails, deleteTicket]
    );

    React.useEffect(() => {
        setLoading(true);

        const queryDocs = query(propRefColLocalTickets, orderBy("createdAt", "desc"));

        const unsubscribe = onSnapshot(
            queryDocs,
            (documentSnapshots) => {
                let _rows =
                    documentSnapshots?.docs?.map((doc) => {
                        return {...doc.data(), id: doc.id};
                    }) ?? [];
                setRows(_rows);
            },
            (e) => {
                enqueueSnackbar(e?.message, {variant: "error"});
            }
        );

        setLoading(false);

        return () => {
            unsubscribe();
        };
    }, [rowsPerPage, enqueueSnackbar, propRefColLocalTickets]);

    const handlePaginationChange = (changes) => {
        setRowsPerPage(changes.pageSize);
    };

    return (
        <>
            <Box {...dgridWrapperBoxProps}>
                <CustomDataGrid
                    {...dgridProps}
                    stickyHeader
                    disableColumnSelector
                    slots={{
                        noRowsOverlay: DataGridNoResult,
                        loadingOverlay: LinearProgress,
                        toolbar: GridToolbar
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: {debounceMs: 500},
                            printOptions: {
                                disableToolbarButton: true
                            }
                        }
                    }}
                    initialState={{
                        pagination: {paginationModel: {pageSize: 25}},
                        columns: {
                            columnVisibilityModel: {
                                tokenCount: forToken,
                                itemOf: !forToken,
                            }
                        }
                    }}
                    pageSizeOptions={[5, 15, 25]}
                    onPaginationModelChange={handlePaginationChange}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                    }
                    loading={loading}
                    columns={
                        columns
                    }
                    rows={rows}
                />
            </Box>
        </>
    );
}
