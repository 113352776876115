import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export default function DataGridNoResult() {
  return (
    <Box
      m={4}
      component={"div"}
      textAlign={"center"}
    >
      <Typography variant="overline">Nessun elemento da mostrare</Typography>
    </Box>
  );
}
