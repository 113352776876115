import React from "react";
import {orderBy, query, onSnapshot} from "firebase/firestore";
import {Box, Chip, LinearProgress, Paper, Typography} from "@mui/material";
import {Checklist, Festival, Token, Warning, WineBar} from "@mui/icons-material";
import DataGridNoResult from "../DataGridNoResult/DataGridNoResult";
import CustomDataGrid from "../CustomDataGrid/CustomDataGrid";
import {GridToolbar} from "@mui/x-data-grid";
import {useSnackbar} from "notistack";
import PropTypes from "prop-types";
import {PRODUCT_TICKETS} from "../../const/const";
import {PRODUCT_MASTERCLASS} from "../../const/const";
import {PRODUCT_TOKEN} from "../../const/const";


const ProductTypeCell = ({value}) => {
    let icon, label;
    switch (value) {
        case PRODUCT_TICKETS:
            icon = <Festival/>;
            label = "Evento"
            break;
        case PRODUCT_MASTERCLASS:
            icon = <WineBar/>;
            label = "Masterclass"
            break;
        case PRODUCT_TOKEN:
            icon = <Token/>;
            label = "Token"
            break;
        default:
            icon = <Warning/>;
            label = "sconosciuto"
    }
    return (
        <Chip
            icon={icon}
            label={label}
            color="primary"
            size="small"
        />
    );
};

const MemoizedProductTypeCell = React.memo(
    ProductTypeCell,
    (prev, next) => {
        return prev.value === next.value;
    }
);


DataGridCheckins.propTypes = {
    title: PropTypes.string.isRequired,
    dgridProps: PropTypes.object,
    dgridWrapperBoxProps: PropTypes.object,
    propRefColOperatorsLog: PropTypes.object.isRequired,
};

export default function DataGridCheckins({title, dgridProps, dgridWrapperBoxProps, propRefColOperatorsLog}) {
    const {enqueueSnackbar} = useSnackbar();
    const [rows, setRows] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [loading, setLoading] = React.useState(true);

    const formatDate = (params) => {
        return new Intl.DateTimeFormat("it", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
        }).format(new Date(params.value.seconds * 1000));
    };

    const formatWhoCell = (params) => {
        const whoRaw = params.value;
        const whoParts = whoRaw.split("@");
        return whoParts[0];
    };

    const columns = React.useMemo(
        () => [
            {
                field: "who",
                headerName: "Operato da",
                filterable: true,
                sortable: true,
                cellClassName: "capitalize",
                valueFormatter: formatWhoCell,
                minWidth: 180
            },
            {
                field: "at",
                headerName: "Check alle",
                valueFormatter: formatDate,
                filterable: true,
                sortable: true,
                minWidth: 145
            },
            {
                field: "customer",
                headerName: "Acquirente",
                filterable: true,
                sortable: true,
                minWidth: 200
            },
            {
                field: "ticketId",
                headerName: "Ticket ID",
                filterable: true,
                sortable: true,
                minWidth: 320
            },
            {
                field: "product",
                headerName: "Prodotto",
                filterable: true,
                sortable: true,
                minWidth: 200,
                renderCell: (params) => {
                    return <MemoizedProductTypeCell {...params} />;
                }
            }
        ],
        []
    );

    React.useEffect(() => {
        setLoading(true);

        const queryDocs = query(propRefColOperatorsLog, orderBy("at", "desc"));

        const unsubscribe = onSnapshot(
            queryDocs,
            (documentSnapshots) => {
                let _rows =
                    documentSnapshots?.docs?.map((doc) => {
                        return {...doc.data(), id: doc.id};
                    }) ?? [];
                setRows(_rows);
            },
            (e) => {
                enqueueSnackbar(e?.message, {variant: "error"});
            }
        );

        setLoading(false);

        return () => {
            unsubscribe();
        };
    }, [rowsPerPage, enqueueSnackbar, propRefColOperatorsLog]);

    const handlePaginationChange = (changes) => {
        setRowsPerPage(changes.pageSize);
    };

    return (
        <Paper
            sx={{height: "auto"}}
            elevation={4}
        >
            <Box
                p={2}
                display={"flex"}
                alignItems={"center"}
                flexDirection={"row"}
                component={"div"}
            >
                <Box sx={{color: "secondary.main", marginTop: 1}}>
                    <Checklist/>
                </Box>

                <Typography
                    variant="h6"
                    ml={1}
                >
                    Situazione Check-In {title}
                </Typography>
            </Box>
            <Box {...dgridWrapperBoxProps}>
                <CustomDataGrid
                    {...dgridProps}
                    stickyHeader
                    disableColumnSelector
                    slots={{
                        noRowsOverlay: DataGridNoResult,
                        loadingOverlay: LinearProgress,
                        toolbar: GridToolbar
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: {debounceMs: 500},
                            printOptions: {
                                disableToolbarButton: true
                            }
                        }
                    }}
                    initialState={{
                        pagination: {paginationModel: {pageSize: 25}}
                    }}
                    pageSizeOptions={[5, 15, 25]}
                    onPaginationModelChange={handlePaginationChange}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                    }
                    loading={loading}
                    columns={columns}
                    rows={rows}
                />
            </Box>
        </Paper>
    );
}
