import React from "react";
import md5 from "md5";
import { refColOutgoingMail } from "../../FirebaseProvider/FirebaseProvider";
import { doc, updateDoc } from "firebase/firestore";

import { GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { orderBy, query, onSnapshot } from "firebase/firestore";
import {
  Avatar,
  AvatarGroup,
  Box,
  Chip,
  LinearProgress,
  Link,
  Paper,
  Typography
} from "@mui/material";
import { Autorenew, EmailOutlined, Loupe } from "@mui/icons-material";
import DataGridNoResult from "../DataGridNoResult/DataGridNoResult";
import CustomDataGrid from "../CustomDataGrid/CustomDataGrid";

import { STATUS_TYPE } from "./StatusType";
import { MAIL_TYPE } from "./MailType";
import ModalDetails from "../ModalDetails/ModalDetails";
import { useSnackbar } from "notistack";

const RenderStatusCell = ({ value }) => {
  const _state = STATUS_TYPE[value];
  return (
    <Chip
      icon={_state.icon}
      label={_state.label}
      color={_state.color}
      size="small"
    />
  );
};

const RenderMailTypeCell = ({ value }) => {
  const _type = MAIL_TYPE[value];
  return (
    <Chip
      label={_type.label}
      color={_type.color}
      size="small"
    />
  );
};

const RenderMailGravatar = (params) => {
  const { value } = params;

  if (typeof value === "string") {
    const md5Mail = md5(value.toLowerCase());
    const src = `https://www.gravatar.com/avatar/${md5Mail}?d=mp`;

    return <Avatar src={src} />;
  } else if (typeof value === "object") {
    return (
      <AvatarGroup max={3}>
        {value.map((email, idx) => {
          const md5Mail = md5(email.toLowerCase());
          const src = `https://www.gravatar.com/avatar/${md5Mail}?d=mp`;
          return (
            <Avatar
              src={src}
              key={idx}
            />
          );
        })}
      </AvatarGroup>
    );
  } else {
    return "anomalia";
  }
};

const RenderRecepitsLink = ({ value }) => {
  if (typeof value === "string") {
    return <Link href={`mailto:${value}`}>{value}</Link>;
  } else if (typeof value === "object") {
    return (
      <Box>
        {value.map((email, idx) => {
          return (
            <Link
              key={`${email}-${idx}`}
              href={`mailto:${email}`}
              display={"block"}
            >
              {email}
            </Link>
          );
        })}
      </Box>
    );
  } else {
    return "anomalia";
  }
};

const MemoizedRenderStatusCell = React.memo(RenderStatusCell, (prev, next) => {
  return prev.value === next.value;
});

const MemoizedRenderMailTypeCell = React.memo(
  RenderMailTypeCell,
  (prev, next) => {
    return prev.value === next.value;
  }
);

const MemoizedRenderMailGravatar = React.memo(
  RenderMailGravatar,
  (prev, next) => {
    return prev.value === next.value;
  }
);

const MemoizedRenderRecepitsLink = React.memo(
  RenderRecepitsLink,
  (prev, next) => {
    return prev.value === next.value;
  }
);

export default function DataGridEmail({ dgridProps, dgridWrapperBoxProps }) {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setLoading] = React.useState(true);
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const [detailsContent, setDetailsContent] = React.useState(null);

  const onRetry = React.useCallback(
    (mailId) => async () => {
      if (!mailId) return;
      const mailRef = doc(refColOutgoingMail, mailId);
      setLoading(true);
      await updateDoc(mailRef, { "delivery.state": "RETRY" });
      setLoading(false);
    },
    []
  );

  const updateRecepitEmail = async (updatedRow, originalRow) => {
    if (!updatedRow.id) return originalRow;
    if (updatedRow.to.toString() === originalRow.to.toString()) {
      return originalRow;
    }

    setLoading(true);

    const mailRef = doc(refColOutgoingMail, originalRow.id);

    const newMails = updatedRow.to.split(",");

    await updateDoc(mailRef, { to: newMails });
    updatedRow.to = newMails;
    setLoading(false);
    return updatedRow;
  };

  const onShowDetail = React.useCallback(
    (mailId) => () => {
      if (!mailId) return;

      const details = rows.find((row) => {
        return row.id === mailId;
      });

      if (details) {
        let content = (
          <pre
            style={{
              width: "100%",
              overflow: "auto",
              height: "400px",
              backgroundColor: "#ddd",
              padding: "8px",
              border: "1px solid #ccc"
            }}
          >
            {JSON.stringify(details, null, 4)}
          </pre>
        );
        if (details.mailType === MAIL_TYPE.TICKET.conts) {
          content = (
            <div>
              <img
                src={details.template.data.qrcodeUri}
                alt={details.template.data.qrcodeId}
                title={details.template.data.qrcodeId}
                style={{ width: "50%", margin: "0 auto", display: "block" }}
              />
              <pre
                style={{
                  width: "100%",
                  overflow: "auto",
                  height: "300px",
                  backgroundColor: "#ddd",
                  padding: "8px",
                  border: "1px solid #ccc"
                }}
              >
                {JSON.stringify(details, null, 4)}
              </pre>
            </div>
          );
        }

        setDetailsContent(content);
      } else {
        setDetailsContent("Dettagli non trovati");
      }

      setDetailsOpen(true);
    },
    [rows]
  );

  const handleCloseDetails = () => {
    setDetailsOpen(false);
    setDetailsContent(null);
  };

  const columns = React.useMemo(
    () => [
      {
        field: "gravatar",
        headerName: "Gravatar",
        renderCell: (params) => <MemoizedRenderMailGravatar {...params} />,
        filterable: false,
        sortable: false,
        editable: false,
        minWidth: 100,
        valueGetter: (params) => {
          return params.row.to;
        }
      },

      {
        field: "mailType",
        headerName: "Tipo",
        renderCell: (params) => {
          return <MemoizedRenderMailTypeCell {...params} />;
        },
        headerAlign: "center",
        align: "center",
        filterable: true,
        sortable: true,
        minWidth: 140
      },
      {
        field: "to",
        headerName: "Destinatari/o",
        renderCell: (params) => <MemoizedRenderRecepitsLink {...params} />,
        filterable: true,
        sortable: true,
        editable: true,
        minWidth: 240
      },
      {
        field: "telefono",
        headerName: "Telefono",
        filterable: true,
        sortable: true,
        minWidth: 145,
        renderCell: (params) => {
          if (!params.value) return "--";
          return params.value;
        }
      },
      {
        field: "startTime",
        headerName: "Data Invio",
        filterable: false,
        sortable: true,
        minWidth: 145,
        headerAlign: "center",
        align: "center",
        valueGetter: (params) => {
          return params.row.delivery?.startTime;
        },
        valueFormatter: (params) => {
          return new Intl.DateTimeFormat("it", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
          }).format(new Date(params.value.seconds * 1000));
        }
      },
      {
        field: "attempts",
        headerName: "Tentativi",
        filterable: false,
        sortable: true,
        minWidth: 80,
        headerAlign: "center",
        align: "center",
        valueGetter: (params) => {
          return params.row.delivery?.attempts;
        }
      },
      {
        field: "state",
        headerName: "Stato",
        filterable: true,
        sortable: true,
        minWidth: 160,
        headerAlign: "center",
        align: "center",
        valueGetter: (params) => {
          return params.row.delivery?.state;
        },
        renderCell: (params) => {
          return <MemoizedRenderStatusCell {...params} />;
        }
      },
      {
        field: "actions",
        headerName: "Azioni",
        type: "actions",
        filterable: false,
        sortable: false,
        minWidth: 110,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<Autorenew />}
            label="Reinvia"
            onClick={onRetry(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<Loupe />}
            label="Dettagli"
            onClick={onShowDetail(params.id)}
            showInMenu
          />
        ]
      }
    ],
    [onRetry, onShowDetail]
  );

  React.useEffect(() => {
    setLoading(true);

    const queryDocs = query(
      refColOutgoingMail,
      orderBy("delivery.startTime", "desc")
    );

    const unsubscribe = onSnapshot(
      queryDocs,
      (documentSnapshots) => {
        let _rows =
          documentSnapshots?.docs?.map((doc) => {
            return { ...doc.data(), id: doc.id };
          }) ?? [];
        setRows(_rows);
      },
      (e) => {
        enqueueSnackbar(e?.message, { variant: "error" });
      }
    );

    setLoading(false);

    return () => {
      unsubscribe();
    };
  }, [rowsPerPage, enqueueSnackbar]);

  const handlePaginationChange = (changes) => {
    setRowsPerPage(changes.pageSize);
  };

  return (
    <>
      <Paper
        sx={{ height: "auto" }}
        elevation={4}
      >
        <Box
          p={2}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"row"}
          component={"div"}
        >
          <Box sx={{ color: "secondary.main", marginTop: 1 }}>
            <EmailOutlined />
          </Box>
          <Typography
            variant="h6"
            ml={1}
          >
            Situazione Email
          </Typography>
        </Box>
        <Box {...dgridWrapperBoxProps}>
          <CustomDataGrid
            {...dgridProps}
            stickyHeader
            disableColumnSelector
            slots={{
              noRowsOverlay: DataGridNoResult,
              loadingOverlay: LinearProgress,
              toolbar: GridToolbar
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                printOptions: {
                  disableToolbarButton: true
                }
              }
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } }
            }}
            pageSizeOptions={[5, 15, 25]}
            onPaginationModelChange={handlePaginationChange}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            processRowUpdate={updateRecepitEmail}
            onProcessRowUpdateError={(error) => {
              console.error(error);
            }}
            loading={loading}
            columns={columns}
            rows={rows}
          />
        </Box>
      </Paper>
      <ModalDetails
        open={detailsOpen ? true : false}
        handleClose={handleCloseDetails}
        content={detailsContent}
        title={"Dettagli invio"}
      />
    </>
  );
}
