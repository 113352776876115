import {
    Checklist,
    EmailOutlined,
    Insights,
    QrCode,
    SettingsApplications
} from "@mui/icons-material";

import {FaPaypal} from "react-icons/fa";
import {IoTicketSharp} from "react-icons/io5";

const routes = [
    {
        label: "Dash Evento",
        icon: <Insights/>,
        path: "/dashboard-evento",
        show: "all"
    },
    {
        label: "Dash Token",
        icon: <Insights/>,
        path: "/dashboard-token",
        show: "all"
    },
    {
        label: "Dash Masterclass",
        icon: <Insights/>,
        path: "/dashboard-masterclass",
        show: "all"
    },
    {
        label: "Scanner",
        icon: <QrCode/>,
        path: "/scanner",
        show: "all"
    },
    {
        label: "Check-In",
        icon: <Checklist/>,
        path: "/checkins",
        show: "all"
    },
    {
        label: "Ticket",
        icon: <IoTicketSharp/>,
        path: "/tickets",
        show: "desktop"
    },
    {
        label: "Email",
        icon: <EmailOutlined/>,
        path: "/email",
        show: "desktop"
    },
    {
        label: "PayPal",
        icon: <FaPaypal/>,
        path: "/paypal",
        show: "desktop"
    },
    {
        label: "Impostazioni",
        icon: <SettingsApplications/>,
        path: "/impostazioni",
        show: "desktop"
    }
];

export default routes;
