import React from "react";
import {
    BookOnline,
    ConfirmationNumber,
    Euro,
    LocalActivity,
    Lock,
    LockOpen,
    PointOfSale, Reply,
    Save,
    Sell,
    SettingsApplications,
    Storefront,
    TransferWithinAStation
} from "@mui/icons-material";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import {
    Alert,
    Box,
    Button, FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    LinearProgress,
    Paper, Switch,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {getDoc, updateDoc} from "firebase/firestore";
import {refTicketsTokenData} from "../../FirebaseProvider/FirebaseProvider";
import {useConfirm} from "material-ui-confirm";
import {useSnackbar} from "notistack";


export default function TokenOptions() {
    const confirm = useConfirm();
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const [sensitiveInputLocked, setSensitiveInputLocked] = React.useState(true);
    const [noChange, setNoChange] = React.useState(true);

    const [prevState, setPrevState] = React.useState();

    const [state, setState] = React.useState({
        OPEN_TO_BUY: false,
        TICKETS_CHECKED: 0,
        TICKETS_LEFT_ONLINE: 0,
        TICKETS_LEFT_ONSITE: 0,
        TICKETS_TOTAL: 0,
        TICKETS_PRICE: 0,
        TICKETS_SOLD_ONLINE: 0,
        TICKETS_SOLD_ONSITE: 0,
        TICKETS_REFUNDED_ONSITE: 0
    });

    const toggleSensitiveInputLocked = () => {
        if (sensitiveInputLocked) {
            confirm({
                title: "Attenzione",
                description: (
                    <>
                        La modifica del numero totale dei ticket ed il loro costo sono
                        operazioni che non andrebbero fatte a sistema in esercizio.
                        <br/>
                        Il costo del Token influisce sulla moltiplicazione (tot venduti *
                        costo ticket) prodotta nella Dashboard.
                        <br/>
                        La modifica del <i>Totale dei Token</i> deve tener conto della
                        disponibilità materiale dei calici.
                    </>
                ),
                confirmationText: "Minnifuttu",
                cancellationText: "Annulla"
            })
                .then(() => {
                    setSensitiveInputLocked(false);
                })
                .catch((e) => {
                });
        }

        setSensitiveInputLocked(true);
    };

    const updateOnFirebase = () => {
        return updateDoc(refTicketsTokenData, state);
    };

    const handleOnInputChange = (e) => {
        const value = Number(e.target.value);
        if (value >= 0) {
            setState({...state, [e.target.name]: value});
        }
    };

    const handleSwitchChange = (e) => {
        setState({...state, [e.target.name]: e.target.checked});
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (
            state.TICKETS_LEFT_ONLINE + state.TICKETS_LEFT_ONSITE >
            state.TICKETS_TOTAL
        ) {
            enqueueSnackbar(
                `La somma dei disponibili online e quelli in loco non può superare il tetto massimo di ${state.TICKETS_TOTAL} tickets!`,
                {variant: "warning"}
            );
            return;
        }

        confirm({
            title: "Siamo sicuri?",
            description:
                "Stai per modificare valori di vitale importanza per l'applicazione. Vuoi procedere?",
            confirmationText: "Si",
            cancellationText: "Annulla"
        })
            .then(() => {
                setLoading(true);
                return updateOnFirebase();
            })
            .then(() => {
                enqueueSnackbar(`Valori aggiornati!`, {variant: "success"});
                setPrevState(state);
            })
            .catch((e) => {
                setError(e);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    React.useEffect(() => {
        const fetchValue = async () => {
            setLoading(true);
            try {
                const docSnap = await getDoc(refTicketsTokenData);
                if (!docSnap.exists()) {
                    setError({message: "Dati non trovati"});
                    return;
                }
                const data = docSnap.data();
                setState(data);
                setPrevState(data);
            } catch (e) {
                setError(e);
            }
            setLoading(false);
        };

        fetchValue();
    }, []);

    React.useEffect(() => {
        setNoChange(JSON.stringify(state) === JSON.stringify(prevState));
    }, [state, prevState]);

    return (
        <Paper
            sx={{mt: 2, height: "100%"}}
            elevation={4}
        >
            <Box
                py={2}
                px={1}
                display={"flex"}
                alignItems={"center"}
                flexDirection={"row"}
                component={"div"}
            >
                <Box sx={{color: "secondary.main", marginTop: 1}}>
                    <SettingsApplications/>
                </Box>

                <Typography
                    variant="h6"
                    ml={1}
                >
                    Impostazioni Token Evento
                </Typography>
            </Box>
            {loading && <LinearProgress variant="indeterminate"/>}
            <Grid
                container
                component={"form"}
                p={2}
                flexDirection={"column"}
                spacing={4}
                onSubmit={handleSubmit}
            >

                <Grid
                    item
                    xs={12}
                >
                    <Box sx={{display: "flex", alignItems: "center"}}>
                        <AddShoppingCartIcon
                            sx={{
                                color: sensitiveInputLocked ? "primary.main" : "error.main",
                                mr: 2
                            }}
                        />

                        <FormControlLabel
                            control={
                                <Switch
                                    id="open-to-buy"
                                    name="OPEN_TO_BUY"
                                    checked={state["OPEN_TO_BUY"]}
                                    onChange={handleSwitchChange}
                                    inputProps={{'aria-label': 'controlled'}}
                                    disabled={loading}
                                />
                            }
                            label={`Vendita online ${state["OPEN_TO_BUY"] ? 'abilitata' : 'disabilitata'}`}
                        />

                    </Box>
                </Grid>

                <Grid
                    item
                    xs={12}
                >
                    <Box sx={{display: "flex", alignItems: "center"}}>
                        <LocalActivity
                            sx={{
                                color: sensitiveInputLocked ? "primary.main" : "error.main",
                                mr: 2
                            }}
                        />
                        <TextField
                            fullWidth
                            id="ticket-disponibili"
                            name="TICKETS_TOTAL"
                            label="Token Totali"
                            variant="standard"
                            disabled={loading || sensitiveInputLocked}
                            type="number"
                            value={state["TICKETS_TOTAL"] || 0}
                            onChange={handleOnInputChange}
                        />
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{display: "flex", alignItems: "center"}}>
                        <Sell
                            sx={{
                                color: sensitiveInputLocked ? "primary.main" : "error.main",
                                mr: 2
                            }}
                        />
                        <TextField
                            fullWidth
                            id="ticket-price"
                            name="TICKETS_PRICE"
                            label="Costo Token"
                            variant="standard"
                            disabled={loading || sensitiveInputLocked}
                            type="number"
                            value={state["TICKETS_PRICE"] || 0}
                            onChange={handleOnInputChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Euro/>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <Box sx={{display: "flex", alignItems: "center"}}>
                        <ConfirmationNumber sx={{color: "primary.main", mr: 2}}/>
                        <TextField
                            fullWidth
                            id="ticket-disponibili-online"
                            name="TICKETS_LEFT_ONLINE"
                            label="Token Disponibili on-line"
                            variant="standard"
                            disabled={loading}
                            type="number"
                            value={state["TICKETS_LEFT_ONLINE"] || 0}
                            onChange={handleOnInputChange}
                        />
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <Box sx={{display: "flex", alignItems: "center"}}>
                        <Storefront sx={{color: "primary.main", mr: 2}}/>
                        <TextField
                            fullWidth
                            id="ticket-disponibili-onsite"
                            name="TICKETS_LEFT_ONSITE"
                            label="Token Disponibili in Loco"
                            variant="standard"
                            disabled={loading}
                            type="number"
                            value={state["TICKETS_LEFT_ONSITE"] || 0}
                            onChange={handleOnInputChange}
                        />
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{display: "flex", alignItems: "center"}}>
                        <BookOnline sx={{color: "primary.main", mr: 2}}/>
                        <TextField
                            fullWidth
                            id="ticket-venduti-online"
                            name="TICKETS_SOLD_ONLINE"
                            label="Token Venduti Online"
                            variant="standard"
                            disabled={loading}
                            type="number"
                            value={state["TICKETS_SOLD_ONLINE"] || 0}
                            onChange={handleOnInputChange}
                        />
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{display: "flex", alignItems: "center"}}>
                        <PointOfSale sx={{color: "primary.main", mr: 2}}/>
                        <TextField
                            fullWidth
                            id="ticket-venduti-onsite"
                            name="TICKETS_SOLD_ONSITE"
                            label="Token Venduti In Loco"
                            variant="standard"
                            disabled={loading}
                            type="number"
                            value={state["TICKETS_SOLD_ONSITE"] || 0}
                            onChange={handleOnInputChange}
                        />
                    </Box>
                </Grid>

                <Grid item>
                    <Box sx={{display: "flex", alignItems: "center"}}>
                        <TransferWithinAStation sx={{color: "primary.main", mr: 2}}/>
                        <TextField
                            fullWidth
                            id="ticket-checked"
                            name="TICKETS_CHECKED"
                            label="Token Smarcati"
                            variant="standard"
                            disabled={loading}
                            type="number"
                            value={state["TICKETS_CHECKED"] || 0}
                            onChange={handleOnInputChange}
                        />
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{display: "flex", alignItems: "center"}}>
                        <Reply sx={{color: "primary.main", mr: 2}}/>
                        <TextField
                            fullWidth
                            id="ticket-checked"
                            name="TICKETS_REFUNDED_ONSITE"
                            label="Token Rimborsati"
                            variant="standard"
                            disabled={loading}
                            type="number"
                            value={state["TICKETS_REFUNDED_ONSITE"] || 0}
                            onChange={handleOnInputChange}
                        />
                    </Box>
                </Grid>
                {error && (
                    <Grid item>
                        <Alert severity="error">{error}</Alert>
                    </Grid>
                )}
                <Grid
                    item
                    mt={2}
                    container
                    justifyContent={"space-between"}
                >
                    <Tooltip title="Sblocca Costo e Totale">
                        <IconButton onClick={toggleSensitiveInputLocked}>
                            {sensitiveInputLocked ? <Lock/> : <LockOpen/>}
                        </IconButton>
                    </Tooltip>
                    <Button
                        type="submit"
                        variant="contained"
                        startIcon={<Save/>}
                        disabled={loading || noChange}
                    >
                        Aggiorna
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
}
