import React from "react";
import { useZxing } from "react-zxing";

export default function CamScanner({ onSuccessScan, inPause, ...props }) {
  const [paused, setPaused] = React.useState(inPause);

  const { ref } = useZxing({
    paused: paused,
    onResult(result) {
      onSuccessScan(result);
    }
  });

  React.useEffect(() => {
    setPaused(inPause);

    return () => {
      setPaused(true);
    };
  }, [inPause]);

  return (
    <video
      ref={ref}
      {...props}
    />
  );
}
