import React from "react";
import DataGridTickets from "../../components/DataGridTickets/DataGridTickets";
import {Box, Tab, Tabs} from "@mui/material";
import CustomTabPanel, {a11yProps} from "../../components/CustomTabPanel/CustomTabPanel";
import {
    refColLocalTicketsEvent,
    refColLocalTicketsMasterclass,
    refColLocalTicketsToken,
    refColTicketsEvent,
    refColTicketsMasterclass,
    refColTicketsToken,
    refTicketsData,
    refTicketsMasterclassData,
    refTicketsTokenData
} from "../../FirebaseProvider/FirebaseProvider";


export default function Tickets() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={handleChange}
                      aria-label="basic tabs example"
                      variant={"scrollable"}
                      scrollButtons={"auto"}>
                    <Tab label="Evento" {...a11yProps(0)} />
                    <Tab label="Masterclass" {...a11yProps(1)} />
                    <Tab label="Token" {...a11yProps(2)} />
                </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
                <DataGridTickets
                    title={"Ticket Evento"}
                    forToken={false}
                    dgridWrapperBoxProps={{sx: {height: "77vh", mb: -2}}}
                    dgridProps={{height: "100%"}}
                    defaultView="online"
                    propRefColLocalTickets={refColLocalTicketsEvent}
                    propRefColOnlineTickets={refColTicketsEvent}
                    propRefTicketsData={refTicketsData}
                />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
                <DataGridTickets
                    title={"Ticket Masterclass"}
                    forToken={false}
                    dgridWrapperBoxProps={{sx: {height: "77vh", mb: -2}}}
                    dgridProps={{height: "100%"}}
                    defaultView="online"
                    propRefColLocalTickets={refColLocalTicketsMasterclass}
                    propRefColOnlineTickets={refColTicketsMasterclass}
                    propRefTicketsData={refTicketsMasterclassData}
                />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
                <DataGridTickets
                    title={"Token"}
                    forToken={true}
                    dgridWrapperBoxProps={{sx: {height: "77vh", mb: -2}}}
                    dgridProps={{height: "100%"}}
                    defaultView="online"
                    propRefColLocalTickets={refColLocalTicketsToken}
                    propRefColOnlineTickets={refColTicketsToken}
                    propRefTicketsData={refTicketsTokenData}
                />
            </CustomTabPanel>

        </>
    );
}
