import useAuthContext from "./context/AuthContext/useAuthContext";
import Login from "./pages/Login/Login";
import Main from "./Main";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([{ path: "*", Component: Main }]);

function App() {
  const { user, authError, authLoading } = useAuthContext();

  if (user) {
    return (
      <RouterProvider router={router}>
        <Main />;
      </RouterProvider>
    );
  }

  return (
    <Login
      authError={authError}
      authLoading={authLoading}
    />
  );
}

export default App;
