const MAIL_TYPE = {
  TICKET: {
    label: "Ticket",
    color: "primary",
    conts: "TICKET"
  },
  ADMIN_NOTICE: {
    label: "System",
    color: "secondary",
    const: "ADMIN_NOTICE"
  },
  MAIL_THANKS: {
    label: "Thanks",
    color: "info",
    const: "MAIL_THANKS"
  }
};

export { MAIL_TYPE };
