import React from "react";
import {
    Box,
    Grid,
    Paper,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import {Checklist} from "@mui/icons-material";
import ModalDetails from "../ModalDetails/ModalDetails";
import DataGridTicketsLocal from "./DataGridTicketsLocal";
import DataGridTicketsOnline from "./DataGridTicketsOnline";

import PropTypes from "prop-types";

DataGridTickets.propTypes = {
    defaultView: PropTypes.oneOf(["local", "online"]).isRequired,
    title: PropTypes.string.isRequired,
    dgridProps: PropTypes.object,
    dgridWrapperBoxProps: PropTypes.object,
    propRefColLocalTickets: PropTypes.object.isRequired,
    propRefColOnlineTickets: PropTypes.object.isRequired,
    propRefTicketsData: PropTypes.object.isRequired,
    forToken: PropTypes.bool.isRequired,
};

export default function DataGridTickets(
    {
        title,
        forToken,
        dgridProps,
        dgridWrapperBoxProps,
        defaultView,
        propRefColLocalTickets,
        propRefColOnlineTickets,
        propRefTicketsData
    }) {
    const [detailsContent, setDetailsContent] = React.useState(null);

    const [source, toggleSource] = React.useState(defaultView);

    const onShowDetails = (details) => {
        if (!details) return;
        setDetailsContent(details);
    };

    const handleChange = (e, source) => {
        if (source !== null) {
            toggleSource(source);
        }
    };

    const handleCloseDetails = () => {
        setDetailsContent(null);
    };

    return (
        <>
            <Paper
                sx={{height: "auto"}}
                elevation={4}
            >
                <Grid
                    container
                    p={2}
                    component={"div"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    alignContent={"center"}
                >
                    <Grid
                        item
                        container
                        alignItems={"center"}
                        flexDirection={"row"}
                        xs={6}
                    >
                        <Box sx={{color: "secondary.main", marginTop: 1}}>
                            <Checklist/>
                        </Box>
                        <Typography
                            variant="h6"
                            ml={1}
                        >
                            {title}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent={"end"}
                        xs={6}
                    >
                        <ToggleButtonGroup
                            color="primary"
                            size="small"
                            value={source}
                            exclusive
                            onChange={handleChange}
                            sx={{ml: "auto"}}
                        >
                            <ToggleButton value="online">Online</ToggleButton>
                            <ToggleButton value="local">In Loco</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
                {source === "local" && (
                    <DataGridTicketsLocal
                        forToken={forToken}
                        propRefColLocalTickets={propRefColLocalTickets}
                        propRefTicketsData={propRefTicketsData}
                        onShowDetails={onShowDetails}
                        dgridProps={dgridProps}
                        dgridWrapperBoxProps={dgridWrapperBoxProps}
                    />
                )}
                {source === "online" && (
                    <DataGridTicketsOnline
                        forToken={forToken}
                        propRefColOnlineTickets={propRefColOnlineTickets}
                        onShowDetails={onShowDetails}
                        dgridProps={dgridProps}
                        dgridWrapperBoxProps={dgridWrapperBoxProps}
                    />
                )}
            </Paper>
            <ModalDetails
                open={detailsContent ? true : false}
                handleClose={handleCloseDetails}
                content={detailsContent}
                title={"Ticket Details"}
            />
        </>
    );
}
