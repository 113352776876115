import React from "react";

import {Container, Tabs, Tab, Box} from "@mui/material";
import TicketOptions from "./TicketOptions";
import OtherOptions from "./OtherOptions";
import MasterclassOptions from "./MasterclassOptions";
import TokenOptions from "./TokenOptions";
import CustomTabPanel, {a11yProps} from "../../components/CustomTabPanel/CustomTabPanel";

export default function Impostazioni() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Container maxWidth="lg">
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={handleChange}
                      aria-label="basic tabs example"
                      variant={"scrollable"}
                      scrollButtons={"auto"}>
                    <Tab label="Evento" {...a11yProps(0)} />
                    <Tab label="Masterclass" {...a11yProps(1)} />
                    <Tab label="Token" {...a11yProps(2)} />
                    <Tab label="Altro" {...a11yProps(3)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <TicketOptions/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <MasterclassOptions/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <TokenOptions/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                <OtherOptions/>
            </CustomTabPanel>
        </Container>
    );
}
