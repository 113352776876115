import React from "react";
import PropTypes from "prop-types";

import { RemoveRedEye, VisibilityOff, WarningAmber } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  Skeleton,
  Typography
} from "@mui/material";

DashBox.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  loading: PropTypes.bool,
  error: PropTypes.object,
  currency: PropTypes.bool,
  multiple: PropTypes.bool
};

const EurCurrency = new Intl.NumberFormat("it-IT", {
  style: "currency",
  currency: "EUR"
});

export default function DashBox({
  icon,
  title,
  value,
  loading,
  error,
  currency,
  multiple,
  variant
}) {
  const [contentVisible, setContentVisible] = React.useState(true);
  const toggleContentVisibility = () => {
    setContentVisible(!contentVisible);
  };

  return (
    <Card
      elevation={4}
      sx={{ height: "100%" }}
    >
      <CardHeader
        sx={{ mb: 0, pb: 0 }}
        avatar={<Box sx={{ color: "secondary.main" }}>{icon}</Box>}
        action={
          <IconButton onClick={toggleContentVisibility}>
            {contentVisible ? <VisibilityOff /> : <RemoveRedEye />}
          </IconButton>
        }
        titleTypographyProps={{
          sx: {
            fontSize: 14,
            color: "text.secondary",
            width: "100%"
          },
          gutterBottom: true,
          variant: "overline",
          noWrap: true
        }}
        title={title}
      />
      <CardContent>
        {loading && (
          <CircularProgress
            sx={{ mx: "auto", display: "block" }}
            variant="indeterminate"
            color="secondary"
          />
        )}
        {error && (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <WarningAmber
              sx={{ display: "inline-block", mr: 1 }}
              color="error"
            />
            Errore recupero dati
          </Box>
        )}
        {!loading &&
          !error &&
          (multiple ? (
            <MultipleValue
              values={value}
              currency={currency}
              visible={contentVisible}
            />
          ) : (
            <SingleValue
              value={value}
              currency={currency}
              visible={contentVisible}
              variant={variant}
            />
          ))}
      </CardContent>
    </Card>
  );
}

const SingleValue = ({ value, currency, visible, variant }) => {
  const _value = currency ? EurCurrency.format(value) : value;

  if (!visible) {
    return <SingleValueSkeleton />;
  }

  return (
    <Typography
      variant={variant || "h4"}
      component="div"
      noWrap
      title={_value}
      sx={{ fontWeight: 600, mt: 2, textAlign: "center" }}
    >
      {_value}
    </Typography>
  );
};

const SingleValueSkeleton = () => {
  return (
    <Typography
      variant="h4"
      component="div"
      sx={{ fontWeight: 600, textAlign: "center" }}
    >
      <Skeleton
        animation={false}
        width={"70%"}
        height={"80px"}
        sx={{ mx: "auto" }}
      />
    </Typography>
  );
};

const MultipleValue = ({ values, currency, visible }) => {
  if (!visible) return <MultipleValueSkeleton values={values} />;

  return (
    <Grid
      container
      flexDirection={"row"}
      flexWrap={"nowrap"}
      flexBasis={0}
      flexGrow={1}
    >
      {values.map((value, key) => {
        const _valueCount = currency
          ? EurCurrency.format(value.count)
          : value.count;
        return (
          <Grid
            key={key}
            item
            container
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Typography
              variant="h4"
              component="div"
              noWrap
              title={_valueCount}
              sx={{ fontWeight: 600, mt: 2, textAlign: "center" }}
            >
              {_valueCount}
              <br />
            </Typography>
            <Typography
              title={value.label}
              noWrap
            >
              {value.label}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

const MultipleValueSkeleton = ({ values }) => {
  return (
    <Grid
      container
      flexDirection={"row"}
      flexWrap={"nowrap"}
      flexBasis={0}
      flexGrow={1}
    >
      {values.map((_value, key) => {
        return (
          <Grid
            key={key}
            item
            container
            alignItems={"center"}
            flexDirection={"column"}
            spacing={0}
          >
            <Skeleton
              animation={false}
              height={"80px"}
              width={"80%"}
              sx={{ mb: -2, mt: -0.8 }}
            />
            <Skeleton
              animation={false}
              height={"40px"}
              width={"80%"}
              sx={{ mb: -2 }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
