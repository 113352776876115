import React from "react";
import ReactDOM from "react-dom/client";
import AuthContextProvider from "./context/AuthContext/AuthContextProvider";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { itIT as datagridItLocale } from "@mui/x-data-grid";
import { itIT as coreItLocale } from "@mui/material/locale";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

const defaultTheme = createTheme(
  {
    palette: {
      primary: {
        main: "#0c2256"
      },
      secondary: {
        main: "#8a1a5f"
      }
    }
  },
  datagridItLocale,
  coreItLocale
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={defaultTheme}>
      <AuthContextProvider>
        <CssBaseline />
        <ConfirmProvider>
          <SnackbarProvider
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            preventDuplicate={true}
          >
            <App />
          </SnackbarProvider>
        </ConfirmProvider>
      </AuthContextProvider>
    </ThemeProvider>
  </React.StrictMode>
);
